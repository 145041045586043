import React, { createContext, useContext, useEffect, useState } from "react";
import { api } from "../services/api";
import { getLocalStore } from "../util/helper";
import { useHistory } from "react-router-dom";
import { AVALIADOR, ENGAJAMENTO, ESCREVE_CASE } from "../config";
import { Option } from "../components/Select/types";

interface UseCaseData {
    relation: RelationUser[];

    EPS: Option[];

    modal: boolean;

    loading: boolean;
    error: boolean;
    success: boolean;

    successPilar: boolean;
    successVinculo: boolean;
    successCase: boolean;

    cases?: CasesArrayData;
    caseById?: CasesData[];
    caseId?: number;
    cardCases: CardCases;
    limitCase: boolean;
    countCases?: number;

    engajamento?: EngajamentoData;

    relationsUser: RelationsUser[];

    descriptionCase?: DescriptionCase;
    motivoReprova: string;
    modalReprova: boolean;

    funcionarios: FuncionariosCase[];
    getFuncionarioByCPF: (value: string) => void;

    addPilar: (values: PilarValues) => void;
    updatePilar: (values: PilarValues) => void;
    addVinculo: (values: funcionarioValues) => void;
    addCase: (values: CaseValues) => void;
    deleteCase: (id: number) => void;
    paginacao: (id: number) => void;
    paginacaoAvaliacao: (id: number) => void;
    paginacaoEngajamento: (id: number) => void;

    caseAvaliacao: (values: CaseAvaliacao) => void;
    hasLimitCase: (values: boolean) => void;
    setCaseId: (id: number | undefined) => void;

    desativarCase: (value: string) => void;
    restaurarCase: () => void;

    handleDescriptionCase: (value: DescriptionCase) => void;
    handleMotivoReprova: (value: string) => void;

    toggleModal: (modal: boolean) => void;
    closeModal: () => void;
}

interface CardCases {
    [key: string]: CardNegocio;
}

interface CardNegocio {
    [key: string]: CardNivel;
}

interface CardNivel {
    [key: string]: CardValues;
}

interface CardValues {
    [key: string]: string;
}

interface UseCaseProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

export interface FuncionariosCase {
    id: number;
    central: string;
    nome: string;
    cpf: string;
    supervisor: string;
    coordenador: string;
    active: boolean;
}

interface PilarValues {
    titulo: string;
    eps: number;
    negocios: number;
    nivel: number;
    pilar: number;
}

interface funcionarioValues {
    funcionario_id: number;
    case_id: number;
}

interface CaseValues {
    dataTime: string;
    atitude_diferenciada: string;
    descricao: string;
}

interface AddCaseData {
    id: number;
    meta_relations_id: number;
    user_id: number;
    original?: {
        case: CaseRelations[];
    };
}

interface CaseRelations {
    atitude_diferenciada: string;
    data_ligacao: string;
    descricao: string;
    funcionario_id: number;
    hora_ligacao: string;
    id: number;
    meta_relations_id: number;
    titulo: string;
    user_id: number;
}

interface Pilar {
    id: number;
    name: string;
}

interface Negocio {
    id: number;
    name: string;
}

interface Eps {
    id: number;
    name: string;
}

interface Nivel {
    id: number;
    name: string;
}

interface MetaRelations {
    id: number;
    eps_id: number;
    negocios_id: number;
    nivel_id: number;
    pilar_id: number;
    pilar: Pilar;
    negocio: Negocio;
    eps: Eps;
    nivel: Nivel;
}

interface RelationUser {
    id: number;
    user_id: number;
    meta_relations_id: number;
    meta_relations: MetaRelations;
}

interface CaseNota {
    avaliador_id: number;
    id: number;
    nota: number;
}

export interface CasesData {
    id: number;
    titulo: string;
    descricao?: string;
    data_ligacao?: string;
    hora_ligacao?: string;
    funcionario_id: number;
    funcionario?: FuncionarioById;
    atitude_diferenciada?: string;
    monitor?: string;
    nota?: CaseNota;
    deleted_at?: string;
    motivo_reprova?: string;
    meta_relacao: {
        eps: Eps;
        negocio: Negocio;
        nivel: Nivel;
        pilar: Pilar;
    };
}

interface CasesArrayData {
    data: CasesData[];
    last_page: number;
    current_page: number;
    total: number;
}

interface DescriptionCase {
    titulo: string;
    data_ligacao: string;
    hora_ligacao: string;
    descricao: string;
    monitor: string;
}

interface stateType {
    id: number;
}

interface CaseAvaliacao {
    case_id: number;
    nota: number;
}

interface RelationsUser {
    id: number;
    user_id: number;
    meta_relations: MetaRelations;
    meta_relations_id: number;
}

interface MetaRelationEngajamento {
    eps: Eps;
    negocio: Negocio;
    nivel: Nivel;
    pilar: Pilar;
}

interface FuncionarioEngajamento {
    id?: number;
    nome?: string;
    central?: string;
}

interface MediaEngajamento {
    case_id: number;
    total: number;
}

export interface Engajamento {
    id: number;
    titulo: string;
    descricao: string;
    data_ligacao: string;
    hora_ligacao: string;
    atitude_diferenciada: string;
    deleted_at: string | null;
    meta_relacao: MetaRelationEngajamento;
    funcionario?: FuncionarioEngajamento;
    monitor?: string;
    motivo_reprova?: string;
    total: [MediaEngajamento];
}

interface EngajamentoData {
    data: Engajamento[];
    last_page: number;
    current_page: number;
    total: number;
}

interface countCase {
    cases: number;
}

export interface FuncionarioById {
    id: number;
    central: string;
    nome: string;
    cpf: string;
    supervisor: string;
    coordenador: string;
}

const UseCaseContext = createContext<UseCaseData>({} as UseCaseData);

export function UseCaseProvider(props: UseCaseProviderProps) {
    const { children } = props;
    const history = useHistory<stateType>();
    const id = history.location.state?.id;

    const [relation, setRelation] = useState<RelationUser[]>([]);

    const [EPS, setEPS] = useState<Option[]>([]);

    const [modal, setModal] = useState<boolean>(false);

    const [success, setSuccess] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const [successPilar, setSuccessPilar] = useState<boolean>(false);
    const [successVinculo, setSuccessVinculo] = useState<boolean>(false);
    const [successCase, setSuccessCase] = useState<boolean>(false);

    const [cases, setCases] = useState<CasesArrayData>();
    const [caseById, setCaseById] = useState<CasesData[]>([]);
    const [caseId, setCaseId] = useState<number>();
    const [cardCases, setCardCases] = useState<CardCases>({});
    const [limitCase, setlimitCase] = useState<boolean>(false);
    const [countCases, setCountCases] = useState<number>();

    const [engajamento, setEngajamento] = useState<EngajamentoData>();

    const [relationsUser, setrelationsUser] = useState<RelationsUser[]>([]);

    const [descriptionCase, setDescriptionCase] = useState<DescriptionCase>();

    const [motivoReprova, setMotivoReprova] = useState<string>("");
    const [modalReprova, setModalReprova] = useState<boolean>(false);

    const [funcionarios, setFuncionarios] = useState<FuncionariosCase[]>([]);
    const [funcionarioId, setfuncionarioId] = useState<number>();

    /**Relations */
    useEffect(() => {
        const local = getLocalStore();
        const id = local?.id;

        async function getRelations() {
            try {
                if (id) {
                    const { data } = await api.get<RelationUser[]>(
                        `/meta_user/show/${id}`
                    );

                    setRelation(data);

                    const eps: Option[] = data.map((item) => {
                        const { meta_relations } = item;
                        const { eps } = meta_relations;
                        const { id, name } = eps;

                        return { label: name, value: id };
                    });

                    const epsUniq: Option[] = Object.values(
                        eps.reduce(
                            (acc, cur) =>
                                Object.assign(acc, { [cur.value]: cur }),
                            {}
                        )
                    );

                    setEPS(epsUniq);
                }
            } catch (error) {
                console.log(error);
            }
        }

        async function relationsUser() {
            setLoading(true);
            try {
                const { data } = await api.get<RelationsUser[]>(`/meta_user`);

                setrelationsUser(data);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }

        if (local?.nivel !== ENGAJAMENTO) {
            relationsUser();
            getRelations();
        }
    }, []);

    //**Cases */
    useEffect(() => {
        const local = getLocalStore();

        async function getCasesAll() {
            setLoading(true);
            try {
                const { data } = await api.get<CasesArrayData>(`/case/show/0`);

                setCases(data);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }

        async function getCasesAvalidador() {
            setLoading(true);
            try {
                const { data } = await api.get<CasesArrayData>(
                    `/case_avaliacao`
                );

                setCases(data);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }

        if (local?.nivel === ESCREVE_CASE) {
            getCasesAll();
        }

        if (local?.nivel === AVALIADOR) {
            getCasesAvalidador();
        }
    }, [success]);

    /**Cases By id */
    useEffect(() => {
        async function getByIdCase(id: number) {
            try {
                const { data } = await api.get<CasesData[]>(`case/id/${id}`);
                setCaseById(data);
            } catch (error) {
                console.log(error);
            }
        }

        if (id) {
            getByIdCase(id);
        }
    }, [id]);

    /**Case card */
    useEffect(() => {
        const local = getLocalStore();

        async function getCarSlide() {
            try {
                const { data } = await api.get<CardCases>("/case/casePilar");
                setCardCases(data);
            } catch (error) {
                setError(true);
            }
        }

        if (local?.nivel === ESCREVE_CASE) {
            getCarSlide();
        }
    }, [success]);

    /**Engajamento */
    useEffect(() => {
        const local = getLocalStore();

        async function getEngajamento() {
            try {
                const { data } = await api.get<EngajamentoData>("/engajamento");
                console.log(data);
                setEngajamento(data);
            } catch (error) {
                setError(true);
            }
        }

        if (local?.nivel === ENGAJAMENTO) {
            getEngajamento();
        }
    }, [success]);

    useEffect(() => {
        const local = getLocalStore();

        async function getEngajamento() {
            try {
                const { data } = await api.get<countCase>("/case/countCase");
                setCountCases(data.cases);
            } catch (error) {
                setError(true);
            }
        }

        if (local?.nivel === ENGAJAMENTO) {
            getEngajamento();
        }
    }, [success]);

    async function addPilar(values: PilarValues) {
        const local = getLocalStore();

        try {
            const { data } = await api.post<AddCaseData>("/case", {
                ...values,
                user_id: local?.id,
            });

            if (data.id) {
                setCaseId(data.id);
                setSuccessPilar(true);
            } else {
                setError(true);
            }
        } catch (error) {
            setError(true);
            console.log(error);
        }
    }

    async function updatePilar(values: PilarValues) {
        const { titulo } = values;

        try {
            await api.put<AddCaseData>(`/case/${id}`, {
                titulo,
            });
            setCaseId(id);
            setSuccessPilar(true);
        } catch (error) {
            setError(true);
            console.log(error);
        }
    }

    async function addVinculo(values: funcionarioValues) {
        try {
            await api.put(`/case/${caseId}`, { ...values });
            setfuncionarioId(values.funcionario_id);
            setSuccessVinculo(true);
        } catch (error) {
            console.log(error);
            setError(true);
        }
    }

    async function addCase(values: CaseValues) {
        const date = values.dataTime
            .split(" ")[0]
            .split("/")
            .reverse()
            .join("-");
        const hora = values.dataTime.split(" ")[1].substring(0, 5);

        const data = {
            data_ligacao: date,
            hora_ligacao: hora,
            descricao: values.descricao,
            atitude_diferenciada: values.atitude_diferenciada,
            funcionario_id: funcionarioId,
        };

        try {
            await api.put(`/case/${caseId}`, { ...data });
            setSuccessCase(true);

            setSuccess(true);

            setTimeout(() => {
                history.goBack();
            }, 3000);
        } catch (error) {
            setError(true);
            console.log(error);
        }
    }

    async function deleteCase() {
        try {
            await api.delete(`case/${caseId}`);
            setSuccess(true);
            setCaseId(undefined);
        } catch (error) {
            setError(true);
        }
    }

    async function caseAvaliacao(values: CaseAvaliacao) {
        try {
            await api.post<CaseNota>("/case_avaliacao", {
                ...values,
            });
            setSuccess(true);
        } catch (error) {
            console.log(error);
            setError(true);
        }
    }

    async function getFuncionarioByCPF(value: string) {
        try {
            const { data } = await api.post<FuncionarioById[]>(
                "/funcionario/findCpf",
                {
                    cpf: value,
                }
            );

            if (data) {
                const funcionarios = data.map((item) => {
                    return { ...item, active: false };
                });

                setFuncionarios(funcionarios);
            }
        } catch (error) {
            setError(true);
        }
    }

    async function desativarCase(value: string) {
        try {
            if (caseId) {
                await api.post(`/engajamento/deleteAndComment/${caseId}`, {
                    motivo_reprova: value,
                });
                setCaseId(undefined);
                setSuccess(true);

                setTimeout(() => {
                    setSuccess(false);
                }, 2000);
            }
        } catch (error) {
            setCaseId(undefined);
            setError(true);
        }
    }

    async function restaurarCase() {
        try {
            if (caseId) {
                await api.post(`/engajamento/${caseId}`);
                setCaseId(undefined);
                setSuccess(true);

                setTimeout(() => {
                    setSuccess(false);
                }, 2000);
            }
        } catch (error) {
            setCaseId(undefined);
            setError(true);
        }
    }

    async function paginacao(id: number) {
        setLoading(true);
        try {
            const { data } = await api.get<CasesArrayData>(
                `case/show/0?page=${id}`
            );
            setCases(data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    async function paginacaoAvaliacao(id: number) {
        setLoading(true);
        try {
            const { data } = await api.get<CasesArrayData>(
                `case_avaliacao?page=${id}`
            );
            setCases(data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    async function paginacaoEngajamento(id: number) {
        setLoading(true);
        try {
            const { data } = await api.get<EngajamentoData>(
                `engajamento?page=${id}`
            );
            setEngajamento(data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    function handleDescriptionCase(value: DescriptionCase) {
        setDescriptionCase(value);
        setModal(true);
    }

    function handleMotivoReprova(value: string) {
        setMotivoReprova(value);
        setModalReprova(true);
    }

    function toggleModal(modal: boolean) {
        setModal(modal);
        setError(modal);
        setModalReprova(modal);
    }

    function closeModal() {
        setModal(false);
        setSuccess(false);
        setError(false);
    }

    function hasLimitCase(value: boolean) {
        setlimitCase(value);
    }

    return (
        <UseCaseContext.Provider
            value={{
                relation,

                EPS,

                modal,
                loading,
                error,
                success,

                successPilar,
                successVinculo,
                successCase,

                cases,
                caseById,
                caseId,
                setCaseId,
                cardCases,
                limitCase,
                deleteCase,
                countCases,

                paginacao,
                paginacaoAvaliacao,
                paginacaoEngajamento,

                engajamento,

                desativarCase,
                restaurarCase,

                descriptionCase,
                funcionarios,
                getFuncionarioByCPF,

                addPilar,
                updatePilar,
                addVinculo,
                addCase,
                caseAvaliacao,
                hasLimitCase,

                relationsUser,

                handleDescriptionCase,

                handleMotivoReprova,
                motivoReprova,
                modalReprova,

                toggleModal,
                closeModal,
            }}
        >
            {children}
        </UseCaseContext.Provider>
    );
}

export function useCase() {
    const context = useContext(UseCaseContext);
    return context;
}
