import { Text, Box, Flex } from "aplication-yetz";
import { useCase } from "../../hook/UseCase";
import { Container } from "./styles";

import checkImg from "../../assets/sistema/check.svg";

export function PreviewCadastro() {
    const { cases, relationsUser } = useCase();

    return (
        <Container>
            <Box width="185px">
                <Text color="white" bold={true} mb="9px" fontSize="mdl">
                    Você já cadastrou
                </Text>

                <Flex>
                    <h1>
                        <img src={checkImg} alt="" />{" "}
                        {cases?.total ? cases.total : 0}
                        <span>/</span>
                        <small>{relationsUser.length * 3}</small>
                    </h1>
                </Flex>
            </Box>
        </Container>
    );
}
