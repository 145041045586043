import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useCase } from "../../hook/UseCase";
import { LayoutEntityAdd } from "../LayoutEntityAdd";
import { Header } from "./header";
import { Pilar } from "./pilar";
import { Vincular } from "./vincular";
import { Case } from "./case";
import { Modal } from "../Modal";
import { BoxNotificacao } from "../BoxNotificacao";

import imgSucesso from "../../assets/sistema/check-round.svg";
import imgAviso from "../../assets/sistema/aviso.svg";

import { Casess } from "./styles";

const aviso = {
    img: imgAviso,
    title: "Não foi possivel realizar está ação, tente mais tarde.",
    width: 473,
};

interface stateType {
    id: number;
}

export function NovoCase() {
    const cases = useRef<HTMLDivElement>(null);
    const history = useHistory<stateType>();
    const id = history.location.state?.id;
    const {
        successPilar,
        successVinculo,
        error,
        success,
        caseById,
        caseId,
        toggleModal,
    } = useCase();
    const [vinculo, setVinculo] = useState<boolean>(false);
    const [caseActive, setCase] = useState<boolean>(false);
    const [qtdCaractere, setQtdCaractere] = useState<boolean>(false);
    const title = id ? "Editar Case" : "Novo case";

    const sucesso = {
        img: imgSucesso,
        title: id
            ? "Case alterado com sucesso!"
            : "Case cadastrado com sucesso!",
        width: 350,
    };

    const erroQuantidadeCaractere = {
        img: imgAviso,
        title: "Não é possível cadastrar um case com mais de 2.000 caracteres",
        width: 500,
    };

    function handleActiveCases(value: string) {
        if (cases.current) {
            const arrElements: NodeListOf<Element> =
                cases.current.querySelectorAll(".container-case");
            arrElements.forEach((item) => {
                if (item.classList.value.includes(value)) {
                    item.classList.add("active");
                } else {
                    item.classList.remove("active");
                }
            });
        }
    }

    function clickOutModal(e: React.MouseEvent<HTMLElement>) {
        const element = (e.target as HTMLElement).dataset;
        if (element?.closemodal) {
            toggleModal(false);
            setQtdCaractere(false);
        }
    }

    useEffect(() => {
        if (successPilar) {
            setVinculo(true);
            handleActiveCases("vincular");
        }
    }, [successPilar]);

    useEffect(() => {
        if (successVinculo) {
            setCase(true);
            handleActiveCases("card-case");
        }
    }, [successVinculo]);
    //8582635486
    return (
        <>
            <LayoutEntityAdd title={title} width={520}>
                <Header pilar={true} vincular={vinculo} case={caseActive} />

                <Casess id="cases" ref={cases}>
                    <div className="container-case pilar active">
                        <Pilar caseById={caseById} />
                    </div>
                    <div className="container-case vincular ">
                        <Vincular caseById={caseById} caseId={caseId} />
                    </div>
                    <div className="container-case card-case">
                        <Case
                            caseById={caseById}
                            caseId={caseId}
                            setQtdCaractere={setQtdCaractere}
                        />
                    </div>
                </Casess>
            </LayoutEntityAdd>

            <Modal
                component={BoxNotificacao}
                active={success}
                onClick={clickOutModal}
                rest={sucesso}
            />

            <Modal
                component={BoxNotificacao}
                active={qtdCaractere}
                onClick={clickOutModal}
                rest={erroQuantidadeCaractere}
            />

            <Modal
                component={BoxNotificacao}
                onClick={clickOutModal}
                active={error}
                rest={aviso}
            />
        </>
    );
}
