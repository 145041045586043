import { Box } from "aplication-yetz";
import { Button } from "../Button";
import { Formik, Form, FormikProps, ErrorMessage } from "formik";
import { useUser } from "../../hook/UserContext";
import { Card } from "./card";

import {
    validadtionRelations,
    RelationsInitialValues,
    RelationsType,
} from "./type";

import buttonImg from "../../assets/sistema/segmentacao.svg";

import { SegmentacaoStyles } from "./styles";
export function Relations() {
    const { openModal, segmentacaoArr, addMetaUser, loadingMetaUser } =
        useUser();

    const itens: Array<number> = segmentacaoArr.reduce(
        (acc: Array<number>, arr) => {
            arr.segmentacao.forEach((item) => acc.push(item.value));
            return acc;
        },
        []
    );

    const initialValues = itens.length
        ? { meta_relations_id: itens }
        : RelationsInitialValues;

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validadtionRelations}
            onSubmit={(values, actions) => {
                if (!values.meta_relations_id.length) {
                    actions.setFieldError(
                        "meta_relations_id",
                        "Adicione uma segementação"
                    );
                } else {
                    addMetaUser(values.meta_relations_id);
                }
            }}
        >
            {(props: FormikProps<RelationsType>) => {
                return (
                    <Form>
                        <Box width="520px">
                            <SegmentacaoStyles>
                                <h3>Segmentação</h3>

                                <button type="button" onClick={openModal}>
                                    <img src={buttonImg} alt="" />
                                </button>
                            </SegmentacaoStyles>
                            <>
                                {segmentacaoArr.map((item) => {
                                    return item.segmentacao.map((seg) => (
                                        <Card
                                            {...seg}
                                            segmentacao={item.segmentacao}
                                            id={item.id}
                                            key={seg.value}
                                        />
                                    ));
                                })}
                            </>
                        </Box>

                        <ErrorMessage
                            component="small"
                            name="meta_relations_id"
                        />

                        <Box width="217px" mt="32px">
                            <Button
                                title="Adicionar"
                                upperCase={true}
                                loading={loadingMetaUser}
                            />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
}
