import { useState } from "react";
import { Menu } from "../../components/Menu";
import { Option } from "../Select/types";
import { Header } from "./header";
import { SubHeader } from "./subHeader";
import { Tabela } from "./table";

export function Usuarios() {
    const [filtro, setFiltro] = useState<string>("");
    const [perfil, setPerfil] = useState<Option>();

    return (
        <>
            <Menu />

            <div className="content">
                <Header setFiltro={setFiltro} />
                <SubHeader setPerfil={setPerfil} />
                <Tabela filtro={filtro} perfil={perfil?.label} />
            </div>
        </>
    );
}
