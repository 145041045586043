import styled from "styled-components";
import estrelaImg from "../../assets/sistema/estrela.svg";
import favoritoImg from "../../assets/sistema/favorito.svg";
import diamanteImg from "../../assets/sistema/diamante.svg";

import setaEsqImgSlide from "../../assets/sistema/seta-esq.svg";
import setaDirImgSlide from "../../assets/sistema/set-dir.svg";

export const ContainerHeader = styled.div`
    padding-bottom: 24px;
    border-bottom: 1px solid #ffffff1a;
    .btn {
        button {
            font-size: ${({ theme }) => theme.fonts.xss};
        }
    }
`;

export const BoxFilter = styled.div`
    width: 384px;
    input {
        background: transparent;
        color: ${({ theme }) => theme.colors.white};
    }
`;

export const SubHeaderStyles = styled.div`
    padding-top: 64px;
    margin-bottom: 58px;
    display: flex;
    align-items: center;
    padding-bottom: 55px;
    border-bottom: 1px solid #ffffff1a;
    .container-swiper {
        margin-left: 120px;
        position: relative;
        .swiper-case {
            width: 1056px;
        }
        .controles {
            position: absolute;
            top: 50%;
            left: -2.5%;
            transform: translateY(-50%);
            width: 105%;
            height: 13px;
            display: flex;
            justify-content: space-between;
            .seta-esq {
                position: relative;
                top: 0px;
                width: 16px;
                height: 15px;
                display: block;
                cursor: pointer;
                &:before {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    -webkit-mask: url(${setaEsqImgSlide});
                    -webkit-mask-repeat: no-repeat;
                    background: ${({ theme }) => theme.colors.action};
                }
            }

            .seta-dir {
                position: relative;
                top: 0px;
                width: 16px;
                height: 15px;
                display: block;
                cursor: pointer;
                &:before {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    -webkit-mask: url(${setaDirImgSlide});
                    -webkit-mask-repeat: no-repeat;
                    background: ${({ theme }) => theme.colors.action};
                }
            }
        }
    }
`;

export const Container = styled.div`
    height: calc(100% - 115px - 76px - 100px);
    .loader {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const CardStyles = styled.div`
    padding: 29px 37px 28px 27px;
    border: 1px solid ${({ theme }) => theme.colors.line};
    position: relative;
    border-radius: 8px;
    span {
        background-color: ${({ theme }) => theme.colors.secondary};
        border-radius: 4px;
        border: none;
        color: ${({ theme }) => theme.colors.white};
        /* display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; */
        padding: 14px 16px 16px 16px;
        width: 70px;
        height: 72px;
        position: relative;
        &.estrela {
            &:before {
                content: "";
                display: block;
                width: 25px;
                height: 24px;
                position: absolute;
                top: 50px;
                left: 50%;
                transform: translate(-50%, -50%);
                -webkit-mask: url(${estrelaImg});
                -webkit-mask-repeat: no-repeat;
                background: ${({ theme }) => theme.colors.sinalizacao};
            }
        }
        &.coracao {
            &:before {
                content: "";
                display: block;
                width: 25px;
                height: 18px;
                position: absolute;
                top: 51px;
                left: 50%;
                transform: translate(-50%, -50%);
                -webkit-mask: url(${favoritoImg});
                -webkit-mask-repeat: no-repeat;
                background: ${({ theme }) => theme.colors.sinalizacao};
            }
        }
        &.diamante {
            &:before {
                content: "";
                display: block;
                width: 25px;
                height: 24px;
                position: absolute;
                top: 50px;
                left: 50%;
                transform: translate(-50%, -50%);
                -webkit-mask: url(${diamanteImg});
                -webkit-mask-repeat: no-repeat;
                background: ${({ theme }) => theme.colors.sinalizacao};
            }
        }
    }
    &:before {
        content: "";
        display: block;
        width: 36px;
        height: 1px;
        position: absolute;
        top: -2px;
        left: 27px;
        background: ${({ theme }) => theme.colors.sinalizacao};
    }
`;

export const HeaderTableStyles = styled.div`
    display: flex;
    align-items: center;
    .filtros {
        display: flex;
        align-items: center;
        margin-left: auto;
        .filtro {
            margin-right: 64px;
            &:last-child {
                margin-right: 0px;
            }
        }
    }
`;

export const TableStyles = styled.div`
    .loader {
        height: calc(90vh - 76px - 288px - 57px);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .table {
        .header {
            .th {
                &:nth-child(1) {
                    padding-left: 38px;
                }
                &:nth-child(2) {
                    padding-left: 28px;
                }
                &:nth-child(3) {
                    padding-left: 22px;
                }
                &:nth-child(4) {
                    padding-left: 22px;
                }
                &:nth-child(5) {
                    padding-left: 22px;
                }

                &:nth-child(6) {
                    text-align: center;
                }

                &:nth-child(7) {
                    text-align: center;
                }

                &:nth-child(8) {
                    text-align: center;
                }
            }
        }
        .tr {
            .button-table {
                margin: 0 auto;
                display: block;
                width: 24px;
                cursor: pointer;
            }
            &.body {
                padding-right: 0px;
                &.deleted {
                    position: relative;
                    border: 1px solid
                        ${({ theme }) => theme.colors.sinalizacaoErro};
                    .td {
                        opacity: 0.5;
                    }
                    &:before {
                        content: "REMOVIDO PELO ENGAJAMENTO";
                        display: block;
                        width: 300px;
                        height: 70%;
                        background-color: ${({ theme }) =>
                            theme.colors.sinalizacaoErro};
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: ${({ theme }) => theme.colors.white};
                        border-radius: 5px;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        z-index: 30;
                    }
                }
            }
        }
    }
`;
