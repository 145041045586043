import { MouseEvent, MutableRefObject } from "react";
import { User } from "../components/Usuarios/types";
import { Option } from "../components/Select/types";
import { localStoreProps } from "../hook/UseLogin";
import { CasesData, Engajamento } from "../hook/UseCase";

export function clearHeight(itens: HTMLDivElement[]) {
    itens.forEach(function (item) {
        if (item) {
            item.classList.remove("active");
            item.style.height = "75px";
        }
    });
}

export function handleOpen(
    refItens: MutableRefObject<HTMLDivElement[]>,
    e: MouseEvent<Element>,
    key: number
) {
    let el = refItens.current[key];

    let altura = (el.querySelector(".conteudo") as HTMLElement).offsetHeight;

    const checkClass = (e.target as Element).classList.value;
    const checkTag = (e.target as Element).tagName;
    const elementosDisable = [
        "toggle",
        "active toggle",
        "false toggle",
        "undefined toggle",
    ];

    const tagsDisable = ["SPAN"];

    if (
        !elementosDisable.includes(checkClass) &&
        !tagsDisable.includes(checkTag)
    ) {
        if (el?.classList.value.includes("active")) {
            el.style.height = "75px";
            el.classList.remove("active");
        } else {
            clearHeight(refItens.current);
            el?.classList.add("active");

            if (el) {
                el.style.height = altura + "px";
            }
        }
    }
}

export function getToken() {
    if (Object.entries(localStorage)[0]) {
        if (Object.entries(localStorage)[0][1]) {
            const local = JSON.parse(Object.entries(localStorage)[0][1]);
            if (local.token) return local.token;
        }
    }

    return false;
}

export function getLocalStore(): localStoreProps | undefined {
    if (Object.entries(localStorage)[0]) {
        if (Object.entries(localStorage)[0][1]) {
            const local: localStoreProps = JSON.parse(
                Object.entries(localStorage)[0][1]
            );
            return local;
        }
    }
}

export function filtroUsuarios(
    arr: User[],
    filtro: string,
    perfil?: string
): User[] {
    if (perfil) {
        arr = arr.filter((item) => item.perfil === perfil);
    }

    if (arr.length && filtro !== "") {
        arr = arr.filter((user) =>
            JSON.stringify(user, ["name", "email", "roles", "case"])
                .toLocaleLowerCase()
                .includes(filtro.toLocaleLowerCase())
        );

        return arr;
    }

    return arr;
}

interface FiltroOptions extends Option {
    tipo: string;
}

export function filtroCase(
    arr: CasesData[],
    filtro: string,
    selects?: FiltroOptions[]
): CasesData[] {
    selects?.forEach((select) => {
        if (select.tipo === "pilar") {
            arr = arr.filter(
                (item) => item.meta_relacao.pilar.id === select.value
            );
        }

        if (select.tipo === "nivel") {
            arr = arr.filter(
                (item) => item.meta_relacao.nivel.id === select.value
            );
        }

        if (select.tipo === "negocio") {
            arr = arr.filter(
                (item) => item.meta_relacao.negocio.id === select.value
            );
        }

        if (select.tipo === "eps") {
            arr = arr.filter(
                (item) => item.meta_relacao.eps.id === select.value
            );
        }
    });

    if (arr.length && filtro !== "") {
        arr = arr.filter(
            (user) =>
                JSON.stringify(user, ["meta_relacao", "nivel", "name"])
                    .toLocaleLowerCase()
                    .includes(filtro.toLocaleLowerCase()) ||
                JSON.stringify(user, ["meta_relacao", "pilar", "name"])
                    .toLocaleLowerCase()
                    .includes(filtro.toLocaleLowerCase()) ||
                JSON.stringify(user, ["meta_relacao", "negocio", "name"])
                    .toLocaleLowerCase()
                    .includes(filtro.toLocaleLowerCase()) ||
                JSON.stringify(user, ["meta_relacao", "eps", "name"])
                    .toLocaleLowerCase()
                    .includes(filtro.toLocaleLowerCase()) ||
                JSON.stringify(user, ["titulo"])
                    .toLocaleLowerCase()
                    .includes(filtro.toLocaleLowerCase())
        );

        return arr;
    }

    return arr;
}

export function filtroEngajamento(
    arr: Engajamento[],
    filtro: string,
    selects?: FiltroOptions[]
): Engajamento[] {
    selects?.forEach((select) => {
        if (select.tipo === "pilar") {
            arr = arr.filter(
                (item) => item.meta_relacao.pilar.id === select.value
            );
        }

        if (select.tipo === "nivel") {
            arr = arr.filter(
                (item) => item.meta_relacao.nivel.id === select.value
            );
        }

        if (select.tipo === "negocio") {
            arr = arr.filter(
                (item) => item.meta_relacao.negocio.id === select.value
            );
        }

        if (select.tipo === "eps") {
            arr = arr.filter(
                (item) => item.meta_relacao.eps.id === select.value
            );
        }
    });

    if (arr.length && filtro !== "") {
        arr = arr.filter(
            (user) =>
                JSON.stringify(user, ["meta_relacao", "nivel", "name"])
                    .toLocaleLowerCase()
                    .includes(filtro.toLocaleLowerCase()) ||
                JSON.stringify(user, ["meta_relacao", "pilar", "name"])
                    .toLocaleLowerCase()
                    .includes(filtro.toLocaleLowerCase()) ||
                JSON.stringify(user, ["meta_relacao", "negocio", "name"])
                    .toLocaleLowerCase()
                    .includes(filtro.toLocaleLowerCase()) ||
                JSON.stringify(user, ["meta_relacao", "eps", "name"])
                    .toLocaleLowerCase()
                    .includes(filtro.toLocaleLowerCase()) ||
                JSON.stringify(user, ["titulo"])
                    .toLocaleLowerCase()
                    .includes(filtro.toLocaleLowerCase())
        );

        return arr;
    }

    return arr;
}

export function convertDatedbForUser(str: string) {
    console.log(str);
    if (!str) return "";
    return str.split("-").reverse().join(".");
}
