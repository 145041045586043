import { useEffect, useState } from "react";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

interface DrafEditorProps {
    onChange: (value: string) => void;
    values?: string;
}

export function DrafEditor(props: DrafEditorProps) {
    const { onChange, values } = props;

    const [editorState, setEditorState] = useState<EditorState>(
        EditorState.createEmpty()
    );

    useEffect(() => {
        if (values) {
            const contentState = ContentState.createFromBlockArray(
                htmlToDraft(values).contentBlocks
            );

            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState);
        }
    }, [values]);

    return (
        <Editor
            toolbarClassName="toolbar-class"
            editorState={editorState}
            onEditorStateChange={(newState) => {
                setEditorState(newState);
                onChange(
                    draftToHtml(convertToRaw(newState.getCurrentContent()))
                );
            }}
        />
    );
}
