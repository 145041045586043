import { useEffect, useState, ChangeEvent } from "react";
import { useHistory } from "react-router-dom";
import { Box, Flex, Text } from "aplication-yetz";
import { Formik, Form, ErrorMessage, FormikProps, Field } from "formik";
import MaskedInput from "react-maskedinput";
import { useDebounce } from "usehooks-ts";

import { useCase, CasesData, FuncionariosCase } from "../../hook/UseCase";
import { api } from "../../services/api";
import { Card } from "./card";
import { Button, Cancelar } from "../Button";
import { validadtionVincular, VincularValues } from "./types";

export interface Vinculos {
    id: number;
    central: string;
    nome: string;
    cpf: string;
    supervisor: string;
    coordenador: string;
    active: boolean;
}

interface VincularProps {
    caseById?: CasesData[];
    caseId?: number;
}

interface FindCpf {
    funcionario_id: number;
}

export function Vincular(props: VincularProps) {
    const { caseById, caseId } = props;
    const history = useHistory();
    const { addVinculo } = useCase();
    const [value, setValue] = useState<string>("");
    const [valueName, setValueName] = useState<string>("");

    const debouncedValue = useDebounce<string>(
        valueName ? valueName : value,
        1000
    );

    const [initalCPF, setinitalCPF] = useState<string>("");
    const [arrVinculo, setArrVinculo] = useState<Vinculos[]>([]);
    const [funcionarios, setFuncionarios] = useState<FuncionariosCase[]>([]);
    const [funcionario, setFuncionario] = useState<FindCpf>({
        funcionario_id: 0,
    });

    useEffect(() => {
        if (funcionarios.length) {
            setArrVinculo(funcionarios);
        }
    }, [funcionarios]);

    useEffect(() => {
        if (caseById?.length) {
            const hasfuncionario = funcionarios.filter(
                (item) => item.id === caseById[0].funcionario_id
            );

            if (hasfuncionario.length) {
                setinitalCPF(hasfuncionario[0].cpf);
                setFuncionario({ funcionario_id: funcionarios[0].id });
                setArrVinculo(
                    funcionarios.map((item) => {
                        if (item.id === caseById[0].funcionario_id) {
                            return { ...item, active: true };
                        } else {
                            return { ...item, active: false };
                        }
                    })
                );
            }
        }
    }, [caseById, funcionarios]);

    useEffect(() => {
        async function getFucionario(value: string) {
            const regex = new RegExp("^[0-9]*$");

            const isNumber = regex.test(value);

            const { data } = await api.post<FuncionariosCase[]>(
                "/funcionario/findCpf",
                {
                    cpf: isNumber ? value : "",
                    nome: !isNumber ? value : "",
                    meta_relations_id: caseId,
                }
            );

            if (data.length) {
                const funcionarios = data.map((item) => {
                    return { ...item, active: false };
                });

                setFuncionarios(funcionarios);
            }
        }

        if (debouncedValue !== "") {
            getFucionario(debouncedValue);
        }
    }, [debouncedValue, caseId]);

    useEffect(() => {
        async function getFucionario(value: string) {
            const { data } = await api.post<FuncionariosCase[]>(
                "/funcionario/findCpf",
                {
                    cpf: value,
                }
            );

            if (data) {
                const funcionarios = data.map((item) => {
                    return { ...item, active: false };
                });

                setFuncionarios(funcionarios);
            }
        }

        if (caseById?.length) {
            if (caseById[0].funcionario?.cpf) {
                getFucionario(caseById[0].funcionario?.cpf);
            }
        }
    }, [caseById]);

    function selectItem(funcionario_id: number) {
        setFuncionario({ funcionario_id });
        setArrVinculo(
            funcionarios.map((item) => {
                if (item.id === funcionario_id) {
                    return { ...item, active: true };
                } else {
                    return { ...item, active: false };
                }
            })
        );
    }

    var arrFiltrado: Vinculos[] = [];

    if (valueName) {
        arrFiltrado = arrVinculo.filter((item) => {
            return JSON.stringify(item, ["nome"])
                .toLocaleLowerCase()
                .includes(valueName.toLocaleLowerCase());
        });
    } else {
        arrFiltrado = arrVinculo.filter((item) => {
            return JSON.stringify(item, ["cpf"]).includes(
                initalCPF ? initalCPF : value
            );
        });
    }

    function clearStr(str: string) {
        return str.replace(/[^\d]/g, "");
    }

    function cancelar() {
        if (caseId) {
            history.push({
                pathname: `/sistema/editar/${caseId}`,
                state: {
                    id: caseId,
                },
            });

            window.location.reload();
        } else {
            history.push("/sistema");
        }
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValueName("");
        setValue(clearStr(event.target.value));
        setinitalCPF(clearStr(event.target.value));
    };

    const handleChangeName = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setValue("");
        setinitalCPF("");
        setValueName(value.replace(/^[0-9]+$/i, ""));
    };

    return (
        <Formik
            initialValues={funcionario}
            enableReinitialize
            validationSchema={validadtionVincular}
            onSubmit={(values, actions) => {
                if (!values.funcionario_id) {
                    actions.setFieldError(
                        "funcionario_id",
                        "Selecione um card"
                    );
                }
                if (caseId) {
                    addVinculo({ ...values, case_id: caseId });
                }
            }}
        >
            {(props: FormikProps<VincularValues>) => {
                return (
                    <Form>
                        <Flex
                            justifyContent="space-between"
                            alignItems="center"
                            mb="17px"
                        >
                            <Box width="520px">
                                <div className="container-input vincular">
                                    <MaskedInput
                                        type="text"
                                        placeholder="Insira o CPF aqui"
                                        name="funcionario_id"
                                        mask="111.111.111-11"
                                        onChange={(e) => {
                                            handleChange(e);
                                        }}
                                        value={initalCPF ? initalCPF : value}
                                    />
                                </div>

                                <ErrorMessage
                                    component="small"
                                    name="funcionario_id"
                                />
                            </Box>

                            <Text color="white" m="0px 50px">
                                ou
                            </Text>
                            <Box width="520px">
                                <div className="container-input vincular">
                                    <Field
                                        type="text"
                                        placeholder="Insira o nome aqui"
                                        onChange={(
                                            e: ChangeEvent<HTMLInputElement>
                                        ) => {
                                            handleChangeName(e);
                                        }}
                                        value={valueName}
                                    />
                                </div>
                            </Box>
                        </Flex>

                        {arrFiltrado.map((value) => {
                            return (
                                <Card
                                    {...value}
                                    selectItem={selectItem}
                                    key={value.cpf}
                                />
                            );
                        })}

                        <Box width="268px" pt="39px">
                            <Flex justifyContent="space-between">
                                <Box width="116px">
                                    <Cancelar
                                        title="Voltar"
                                        upperCase={true}
                                        onClick={() => cancelar()}
                                    />
                                </Box>

                                <Box width="116px">
                                    <Button title="Avançar" upperCase={true} />
                                </Box>
                            </Flex>
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
}
