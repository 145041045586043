import React, { useEffect, useCallback, memo } from "react";
import { api } from "../services/api";
import { AxiosError } from "axios";

function Interceptor() {
    const errorHandler = (error: AxiosError) => {
        const { response } = error;

        switch (response?.status) {
            case 500:
                console.log("500 ->", response?.status);
                // localStorage.clear();
                break;
            case 404:
                console.log("404 ->", response?.status);
                // localStorage.clear();
                // localStorage.clear();
                break;
            case 401:
                localStorage.clear();
                // window.location.reload();
                break;
            default:
                break;
        }

        return Promise.reject({ ...error });
    };

    const successHandler = useCallback(async (response) => {
        return await response;
    }, []);

    useEffect(() => {
        api.interceptors.response.use(
            (response) => successHandler(response),
            (error: AxiosError) => errorHandler(error)
        );
    }, [successHandler]);

    return <React.Fragment />;
}

export default memo(Interceptor);
