import { createGlobalStyle } from "styled-components";
import emailImg from "../assets/sistema/email.svg";
import cadeadoImg from "../assets/sistema/cadeado.svg";
import vincularImg from "../assets/sistema/cpf.svg";

import fazerMelhorImg from "../assets/sistema/diamante.svg";
import resolverImg from "../assets/sistema/estrela.svg";
import humanizarImg from "../assets/sistema/favorito.svg";

export const GlobalStyle = createGlobalStyle`
    

    *{
        margin: 0px;
        padding: 0px;
        outline: 0;
        box-sizing: border-box;
    }

    #root{
        overflow-x: hidden;
    }

    .content{
        background: ${({ theme }) => theme.colors.fundoBase};
        position: absolute;
        top: 0px;
        left: 228px;
        width: calc(100% - 228px);
        height: 100vh;
        overflow: scroll;
        padding-top: 2.313rem;
        padding-bottom: 2.313rem;
        padding-left: 2.188rem;
        padding-right: 2.938rem;
        overflow-x: hidden;
        &::-webkit-scrollbar {
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar:vertical {
            width: 5px;
        }

        &::-webkit-scrollbar:horizontal {
            height: 12px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(89, 92, 118, 0.5);
        }

        &::-webkit-scrollbar-track {
            background-color: rgba(255, 255, 255, 0.1);
        }
    }

    .container{
        width: 1494px;
        margin: 0 auto;
    }


    html{
        @media(max-width: 1080px){
            font-size: 93.75%;
        }

        @media(max-width: 720px){
            font-size: 87.5%;
        }
    }

    body{
        -webkit-font-smoothing: antialiased;
    }

    body, input, textarea, button{
        font-family: 'Inter', sans-serif;
        font-weight: 400;
    }
    
    h1, h2, h3, h4, h5, h6, strong{
        font-weight: 600;
    }

    small{
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 140%;
        color: #FFFFFF;
    }

    a{
        text-decoration: none;
    }

    button{
        cursor: pointer;
    }

    [disabled]{
        opacity: 0.6;
        cursor: not-allowed;
    }

    .disable{
        opacity: 0.6;
        cursor: not-allowed;
    }

    .pilar {
        position: relative;
        &.fazermelhor {
            &:before {
                content: "";
                display: block;
                width: 25px;
                height: 24px;
                position: absolute;
                top: 50%;
                left: 0px;
                transform: translateY(-50%);
                -webkit-mask: url(${fazerMelhorImg});
                -webkit-mask-repeat: no-repeat;
                background: ${({ theme }) => theme.colors.sinalizacao};
            }
        }
        &.humanizarImg {
            &:before {
                content: "";
                display: block;
                width: 25px;
                height: 24px;
                position: absolute;
                top: 65%;
                left: 0px;
                transform: translateY(-50%);
                -webkit-mask: url(${humanizarImg});
                -webkit-mask-repeat: no-repeat;
                background: ${({ theme }) => theme.colors.sinalizacao};
            }
        }
        &.resolver {
            &:before {
                content: "";
                display: block;
                width: 25px;
                height: 24px;
                position: absolute;
                top: 43%;
                left: 0px;
                transform: translateY(-50%);
                -webkit-mask: url(${resolverImg});
                -webkit-mask-repeat: no-repeat;
                background: ${({ theme }) => theme.colors.sinalizacao};
            }
        }
    }


    .table {
        .header{
            border-bottom: none !important;
            .th{
                font-weight: bold;    
                color: ${({ theme }) => theme.colors.gray700};
                border-bottom: 1px solid ${({ theme }) => theme.colors.line};
                padding-bottom: 16px;
                text-transform: uppercase;
            }
        }

        .tr {
            font-weight: 500;
            background-color: ${({ theme }) => theme.colors.black};
            &.header{
                padding-top: 44px;
                padding-bottom: 24px;
            }
            &.body {
                margin-bottom: 8px;
                height: 82px;
                padding-left: 24px;
                padding-right: 24px;
            }
        }
    }

    .container-input{
        width: 100%;
        height: 56px;
        margin-bottom: 16px;
        position: relative;
        transition: all .2s;
        &.toggle-input{
            height: 0px;
            overflow: hidden;
            transition: all .2s;
        }

        .date-hora{
            color: ${({ theme }) => theme.colors.white};
            position: relative;
            top: 40px;
            left: 15px;
        }
        .MuiInputLabel-root{
            color: rgba(92, 100, 113, 0.9) !important;
            background: ${({ theme }) => theme.colors.fundoBase};
            padding: 0px 5px;
        }
        .MuiFormControl-root{
            width: 100%;
            border: 1px solid rgba(92, 100, 113, 0.9);
            border-radius: 4px;
            input{
                border: none;
                height: 56px;
            }
            .MuiOutlinedInput-notchedOutline{
                border: 1px solid transparent !important;
            }
        }
        &:last-child{
            margin-bottom: 0px;
        }
        
        input, textarea{
            width: 100%;
            height: 100%;
            border: 1px solid rgba(92, 100, 113, 0.9);
            border-radius: 4px;
            
            padding-left: 15px;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: -0.005em;
            color: #FFFFFF;
            opacity: 0.75;

            background-color: transparent;

            &.MuiOutlinedInput-input{
                padding-top: 0px;
                padding-bottom: 0px;
            }
        }

        textarea{
            padding-top: 16px;
            height: 277px;
        }

        &.editor{
            min-height: 300px;
            height: auto;
            background: white;
            .rdw-editor-main{
                padding: 0 16px;
            }
            span{
                color: #000;
            }
        }

        &.email{
            input{
                padding-left: 40px;
            }
            &:before{
                content: "";
                display: block;
                width: 21px;
                height: 18px;
                position: absolute;
                top: 50%;
                left: 21px;
                transform: translate(-50%, -50%);
                -webkit-mask: url(${emailImg});
                -webkit-mask-repeat: no-repeat;
                background: #ffffff80;
            }
        }
        &.senha{
            input{
                padding-left: 40px;
            }
            &:before{
                content: "";
                display: block;
                width: 21px;
                height: 21px;
                position: absolute;
                top: 50%;
                left: 21px;
                transform: translate(-50%, -50%);
                -webkit-mask: url(${cadeadoImg});
                -webkit-mask-repeat: no-repeat;
                background: #ffffff80;
            }
        }

        &.vincular{
            input{
                padding-left: 50px;
                border: 1px solid ${({ theme }) => theme.colors.action};
            }
            &:before{
                content: "";
                display: block;
                width: 24px;
                height: 24px;
                position: absolute;
                top: 50%;
                left: 26px;
                transform: translate(-50%, -50%);
                -webkit-mask: url(${vincularImg});
                -webkit-mask-repeat: no-repeat;
                background: ${({ theme }) => theme.colors.action};
            }
        }
    }

    .combo-box-filtro{
        span{
            color: ${({ theme }) => theme.colors.white};
        }
        &.MuiAutocomplete-root{
            .MuiAutocomplete-input{
                color: ${({ theme }) => theme.colors.white};
            }

            .MuiAutocomplete-popper{
                background: linear-gradient(90deg, #19FFFF 1.92%, rgba(25, 255, 255, 0) 105.77%);
            }

            .MuiInputLabel-root{
                &.MuiInputLabel-formControl{
                    color: ${({ theme }) => theme.colors.action};
                    font-weight: bold;
                    font-size: 12px;
                }
            }
            .MuiOutlinedInput-notchedOutline{
                border: none;
            }
        }
    }

    .combo-box-select{
        border: 1px solid rgba(92,100,113,0.9);
        border-radius: 4px;
        span{
            color: ${({ theme }) => theme.colors.white};
        }
        &.MuiAutocomplete-root{

            &:hover{
                .MuiOutlinedInput-notchedOutline{
                    border: 1px solid #13393B;
                }
            }
            .MuiAutocomplete-input{
                color: #ffffffbf;
            }

            .MuiAutocomplete-popper{
                background: linear-gradient(90deg, #19FFFF 1.92%, rgba(25, 255, 255, 0) 105.77%);
            }

            .MuiInputLabel-root{
                &.MuiInputLabel-formControl{
                    color: ${({ theme }) => theme.colors.action};
                    font-weight: bold;
                    font-size: 12px;
                }
            }
            .MuiAutocomplete-endAdornment{
                top: calc(50% - 12px);
                right: 20px !important;
            }
            .MuiOutlinedInput-notchedOutline{
                border: 1px solid transparent !important;
            }
        }
    }

    .table{
        .tr{
            .button-table {
                margin: 0 auto;
                display: block;
                width: 24px;
                cursor: pointer;
            }
        }
    }
`;
