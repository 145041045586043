import { useUser } from "../../hook/UserContext";
import { LayoutEntityAdd } from "../LayoutEntityAdd";
import { FormUsuario } from "./form";
import { Relations } from "./relation";
import { Modal } from "../Modal";
import { Segmentacao } from "./segmenatacao";
import { BoxNotificacao } from "../BoxNotificacao";

import imgSucesso from "../../assets/sistema/sucesso.svg";
import imgAviso from "../../assets/sistema/aviso.svg";

const sucesso = {
    img: imgSucesso,
    title: "Usuario cadastrado com sucesso!",
};

const aviso = {
    img: imgAviso,
    title: "Não foi possivel realizar o cadastro, tente mais tarde.",
    width: 473,
};

export function NovoUsuario() {
    const { modal, modalSucesso, modalFail, openModal, closeModal, addUserId } =
        useUser();

    function handleModalOpen() {
        openModal();
    }

    function clickOutModal(e: React.MouseEvent<HTMLElement>) {
        const element = (e.target as HTMLElement).dataset;
        if (element?.closemodal) {
            closeModal();
        }
    }

    return (
        <>
            <LayoutEntityAdd title="Novo usuario" width={520}>
                {addUserId ? (
                    <Relations />
                ) : (
                    <FormUsuario openModal={handleModalOpen} />
                )}
            </LayoutEntityAdd>

            <Modal
                component={Segmentacao}
                active={modal}
                onClick={clickOutModal}
                rest={{}}
            />

            <Modal
                component={BoxNotificacao}
                onClick={clickOutModal}
                active={modalSucesso}
                rest={sucesso}
            />

            <Modal
                component={BoxNotificacao}
                onClick={clickOutModal}
                active={modalFail}
                rest={aviso}
            />
        </>
    );
}
