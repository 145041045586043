import { useEffect, useState, useMemo } from "react";
import TextField from "@mui/material/TextField";
import {
    SelectProps,
    MultipleSelectProps,
    FiltroProps,
    DatePickerProps,
    Option,
} from "./types";
import Autocomplete from "@mui/material/Autocomplete";
import setaBaixo from "../../assets/sistema/seta-baixo.svg";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MobileDateTimePicker from "@mui/lab/MobileDateTimePicker";

export function Filtro(props: FiltroProps) {
    const { onChange, options, placeholder, width } = props;

    return (
        <Autocomplete
            disablePortal
            popupIcon={<img src={setaBaixo} alt="seta" />}
            options={options}
            className="combo-box-filtro"
            onChange={(e: object, value: any) => {
                onChange(value);
            }}
            isOptionEqualToValue={(option, value) =>
                option.value === value.value
            }
            sx={{
                width: width ? width : 300,
                color: "red",
                borderBottom: "1px solid #203742;",
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={placeholder ? placeholder : "perfil"}
                />
            )}
        />
    );
}

export function Select(props: SelectProps) {
    const { placeholder, options, onChange, clear, initalValue } = props;

    const value = useMemo(() => {
        return initalValue ? initalValue : { label: "", value: 0 };
    }, [initalValue]);

    const [values, setValues] = useState<Option>(value);

    useEffect(() => {
        if (initalValue) {
            setValues(initalValue);
        }
    }, [initalValue]);

    const clearSelected = () => {
        setValues({ label: "", value: 0 });
    };

    return (
        <>
            <button hidden type="button" ref={clear} onClick={clearSelected}>
                Clear selected
            </button>
            <Autocomplete
                className="combo-box-select"
                popupIcon={<img src={setaBaixo} alt="seta" />}
                options={options}
                value={values}
                getOptionLabel={(option) => option.label}
                defaultValue={values}
                isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                }
                onChange={(e: object, value: any) => {
                    onChange(value ? value : "");
                    setValues(value);
                }}
                sx={{
                    width: "100%",
                    height: "100%",
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        placeholder={placeholder}
                        SelectProps={{ native: true }}
                    />
                )}
            />
        </>
    );
}

export function MultipleSelect(props: MultipleSelectProps) {
    const { placeholder, options, onChange, clear, value } = props;

    const initalValue = useMemo(() => {
        return value ? value : [];
    }, [value]);

    const [values, setValues] = useState<Option[]>(initalValue);

    useEffect(() => {
        if (initalValue.length !== 0) {
            setValues(initalValue);
        }
    }, [initalValue]);

    const clearSelected = () => {
        setValues([]);
    };

    return (
        <>
            <button hidden type="button" ref={clear} onClick={clearSelected}>
                Clear selected
            </button>
            <Autocomplete
                multiple
                filterSelectedOptions
                className="combo-box-select"
                popupIcon={<img src={setaBaixo} alt="seta" />}
                options={options}
                getOptionLabel={(option) => option.label}
                value={values}
                defaultValue={values}
                isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                }
                onChange={(e: object, value: Array<Option>) => {
                    onChange(value);
                    setValues(value);
                }}
                sx={{
                    width: "100%",
                    height: "100%",
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        placeholder={placeholder}
                        SelectProps={{ native: true }}
                    />
                )}
            />
        </>
    );
}

export function DateTime(props: DatePickerProps) {
    const { onChange, initialValue } = props;

    const [text, setText] = useState<string>("");
    const [value, setValue] = useState<Date | null>(initialValue);

    useEffect(() => {
        if (initialValue) {
            var data = new Date(initialValue).toLocaleDateString();
            var time = new Date(initialValue).toLocaleTimeString();
            setText("");
            if (data !== "Invalid Date" && time !== "Invalid Date") {
                if (!value) {
                    setText(`${data} ${time}`);
                }
            }
        }
    }, [initialValue, value]);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDateTimePicker
                value={value}
                onOpen={() => {
                    setText("");
                    setValue(new Date());
                }}
                onChange={(newValue) => {
                    onChange(newValue ? newValue.toLocaleString() : "");
                    setText("");
                    if (newValue) {
                        setValue(newValue);
                    }
                }}
                renderInput={(params) => {
                    return (
                        <>
                            {text ? <p className="date-hora">{text}</p> : null}
                            <TextField
                                {...params}
                                label={"Data & Hora da Ligação"}
                                focused={text ? true : false}
                            />
                        </>
                    );
                }}
            />
        </LocalizationProvider>
    );
}
