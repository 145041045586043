import { useState, useEffect } from "react";
import { Table, Flex, Box, Text } from "aplication-yetz";
import { Link } from "react-router-dom";
import { filtroCase } from "../../util/helper";
import { useCase } from "../../hook/UseCase";
import { column } from "./config";
import { Data } from "./types";
import checkCaseImg from "../../assets/sistema/check-case.svg";

import { Loader } from "../../components/Loader";
import { Option } from "../Select/types";
import { PaginacaoUser } from "../Paginacao";

import visualizarImg from "../../assets/sistema/visualizar.svg";
import editarImg from "../../assets/sistema/editar.svg";
import excluirImg from "../../assets/sistema/excluir-cor-action.svg";
import imgMensagem from "../../assets/sistema/messages.svg";

import { TableStyles } from "./styles";

interface FiltroOptions extends Option {
    tipo: string;
}

interface TableProps {
    filtro: string;
    select?: FiltroOptions[];
    setcaseId: (id: number) => void;
}

export function TableCase(props: TableProps) {
    const {
        cases,
        loading,
        handleDescriptionCase,
        handleMotivoReprova,
        paginacao,
    } = useCase();
    const [currentPage, setSurrentPage] = useState<number>();
    const [lastPage, setLastPage] = useState<number>();
    const { filtro, select, setcaseId } = props;

    const arrFilter = filtroCase(cases?.data ? cases.data : [], filtro, select);

    const dataTemp: Data[] = arrFilter.map((item) => {
        const {
            meta_relacao,
            titulo,
            descricao,
            data_ligacao,
            hora_ligacao,
            id,
            atitude_diferenciada,
            deleted_at,
            monitor,
            motivo_reprova,
        } = item;
        const { eps, negocio, nivel, pilar } = meta_relacao;
        var img;

        // var str = descricao.replace(/\n "/g, "<br />")
        var str = descricao ? descricao.replace(/\\n/g, "<br />") : "";
        str = str.replace(/[\\"]/g, "");

        const descriptionCase = {
            titulo,
            hora_ligacao: hora_ligacao ? hora_ligacao : "",
            data_ligacao: data_ligacao ? data_ligacao : "",
            descricao: str ? str : "",
            atitude: atitude_diferenciada ? atitude_diferenciada : "",
            monitor: monitor ? monitor : "",
        };

        switch (pilar.id) {
            case 3:
                img = "fazermelhor";
                break;

            case 2:
                img = "humanizarImg";
                break;

            case 1:
                img = "resolver";
                break;

            default:
                break;
        }

        return {
            styles: deleted_at ? "deleted" : "",
            eps: (
                <Flex alignItems="center">
                    <img src={checkCaseImg} alt="" />
                    <Text ml="16px" color="white">
                        <>{eps.name}</>
                    </Text>
                </Flex>
            ),
            negocio: <Text color="white">{negocio.name}</Text>,
            nivel: <Text color="white">{nivel.name}</Text>,
            pilar: (
                <div className={`pilar ${img}`}>
                    <Text pl="30px" color="white">
                        {pilar.name}
                    </Text>
                </div>
            ),
            titulo: <Text color="white">{titulo}</Text>,

            lerCase: (
                <div
                    className="button-table"
                    onClick={() => handleDescriptionCase(descriptionCase)}
                >
                    <img src={visualizarImg} alt="" />
                </div>
            ),
            editar: (
                <div className="button-table">
                    <Link
                        to={{
                            pathname: `/sistema/editar/${id}`,
                            state: {
                                id,
                            },
                        }}
                    >
                        <img src={editarImg} alt="" />
                    </Link>
                </div>
            ),
            apagar: (
                <Box m="0 auto" width="24px">
                    <div className="button-table" onClick={() => setcaseId(id)}>
                        <img src={excluirImg} alt="" />
                    </div>
                </Box>
            ),

            motivo:
                motivo_reprova && motivo_reprova !== "" ? (
                    <Box>
                        <div
                            className="button-table"
                            onClick={() => handleMotivoReprova(motivo_reprova)}
                        >
                            <img src={imgMensagem} alt="" />
                        </div>
                    </Box>
                ) : null,
        };
    });

    useEffect(() => {
        if (cases?.current_page && cases.last_page) {
            setSurrentPage(cases.current_page);
            setLastPage(cases.last_page);
        }
    }, [cases?.current_page, cases?.last_page]);

    function paginacaoCases(id: number) {
        paginacao(id);
    }

    return (
        <TableStyles>
            {loading ? (
                <div className="loader">
                    <Loader width={300} height={300} />
                </div>
            ) : (
                <>
                    <Table columns={column} data={dataTemp} />
                    <PaginacaoUser
                        currentPage={currentPage}
                        lastPage={lastPage}
                        paginacao={paginacaoCases}
                    />
                </>
            )}
        </TableStyles>
    );
}
