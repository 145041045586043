import { useState, useEffect } from "react";
import { Table, Text, Flex } from "aplication-yetz";
import { Avaliacao } from "../Avalaicao";
import { column } from "./config";
import { Data } from "./types";
import { TableStyles } from "./styles";
import { useCase } from "../../hook/UseCase";
import { filtroEngajamento } from "../../util/helper";
import { PaginacaoUser } from "../Paginacao";

import visualizarImg from "../../assets/sistema/visualizar.svg";
import excluirImg from "../../assets/sistema/avaliacao/close.svg";
import imgRestore from "../../assets/sistema/avaliacao/restore.svg";
import imgMensagem from "../../assets/sistema/messages.svg";

import { Option } from "../Select/types";
import { Loader } from "../../components/Loader";

interface FiltroOptions extends Option {
    tipo: string;
}

interface TableProps {
    filtro: string;
    select?: FiltroOptions[];
    setcaseId: (id: number) => void;
    setHasRestoreCase: (value: boolean) => void;
}

export function TableAvaliacao(props: TableProps) {
    const [currentPage, setSurrentPage] = useState<number>();
    const [lastPage, setLastPage] = useState<number>();
    const {
        engajamento,
        loading,
        handleDescriptionCase,
        handleMotivoReprova,
        paginacaoEngajamento,
    } = useCase();
    const { filtro, select, setcaseId, setHasRestoreCase } = props;

    const arrFilter = filtroEngajamento(
        engajamento?.data ? engajamento?.data : [],
        filtro,
        select
    );

    const dataTemp: Data[] = arrFilter.map((item) => {
        const {
            meta_relacao,
            titulo,
            descricao,
            data_ligacao,
            hora_ligacao,
            funcionario,
            total,
            id,
            deleted_at,
            atitude_diferenciada,
            monitor,
            motivo_reprova,
        } = item;

        const { negocio, nivel, pilar, eps } = meta_relacao;

        var img;

        var str = descricao ? descricao.replace(/\\n/g, "<br />") : "";
        str = str.replace(/[\\"]/g, "");

        const descriptionCase = {
            titulo,
            hora_ligacao: hora_ligacao ? hora_ligacao : "",
            data_ligacao: data_ligacao ? data_ligacao : "",
            descricao: str ? str : "",
            atitude: atitude_diferenciada ? atitude_diferenciada : "",
            monitor: monitor ? monitor : "",
        };

        switch (pilar.id) {
            case 3:
                img = "fazermelhor";
                break;

            case 2:
                img = "humanizarImg";
                break;

            case 1:
                img = "resolver";
                break;

            default:
                break;
        }

        return {
            styles: deleted_at ? "deleted" : "",

            eps: (
                <Text ml="16px" color="white">
                    {eps.name}
                </Text>
            ),
            negocio: (
                <Text ml="16px" color="white">
                    {negocio.name}
                </Text>
            ),
            nivel: (
                <Text ml="16px" color="white">
                    {nivel.name}
                </Text>
            ),
            pilar: (
                <div className={`pilar ${img}`}>
                    <Text pl="30px" color="white">
                        {pilar.name}
                    </Text>
                    <Text pl="30px" mb="5px" fontSize="sm" color="gray700">
                        {funcionario?.nome}
                    </Text>
                    <Text pl="30px" fontSize="sm" color="gray700">
                        {funcionario?.central}
                    </Text>
                </div>
            ),
            titulo: (
                <Text ml="16px" color="white">
                    {titulo}
                </Text>
            ),
            avaliacao: (
                <Avaliacao
                    rtl={false}
                    id={total.length ? total[0].case_id : 0}
                    nota={total.length ? total[0].total : 0}
                    disabled={true}
                />
            ),
            lerCase: (
                <Flex width="104px" m="0 auto" justifyContent="space-between">
                    <div
                        className="button-table"
                        onClick={() => handleDescriptionCase(descriptionCase)}
                    >
                        <img src={visualizarImg} alt="" />
                    </div>
                    <div
                        className="button-table"
                        onClick={() => {
                            setcaseId(id);
                            setHasRestoreCase(deleted_at ? true : false);
                        }}
                    >
                        <img
                            src={deleted_at ? imgRestore : excluirImg}
                            alt=""
                        />
                    </div>

                    {motivo_reprova && motivo_reprova !== "" ? (
                        <div
                            className="button-table"
                            onClick={() => handleMotivoReprova(motivo_reprova)}
                        >
                            <img src={imgMensagem} alt="" />
                        </div>
                    ) : (
                        <></>
                    )}
                </Flex>
            ),
        };
    });

    useEffect(() => {
        if (engajamento?.current_page && engajamento.last_page) {
            setSurrentPage(engajamento.current_page);
            setLastPage(engajamento.last_page);
        }
    }, [engajamento?.current_page, engajamento?.last_page]);

    return (
        <TableStyles>
            {loading ? (
                <div className="loader">
                    <Loader width={300} height={300} />
                </div>
            ) : (
                <>
                    <Table columns={column} data={dataTemp} />
                    <PaginacaoUser
                        currentPage={currentPage}
                        lastPage={lastPage}
                        paginacao={paginacaoEngajamento}
                    />
                </>
            )}
        </TableStyles>
    );
}
