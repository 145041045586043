import { Flex, Filter, Box } from "aplication-yetz";
import { ChangeEvent } from "react";
import { Link } from "react-router-dom";
import { Button } from "../../components/Button";
import { NomeUser } from "../../components/NomeUser";
import { getLocalStore } from "../../util/helper";

import { BoxFilter, ContainerHeader } from "./styles";

interface HeaderProps {
    setFiltro: (e: string) => void;
}

export function Header(props: HeaderProps) {
    const { setFiltro } = props;

    const itens = getLocalStore();

    return (
        <ContainerHeader>
            <Flex justifyContent="space-between">
                <NomeUser name={itens ? itens.user : ""} />

                <BoxFilter>
                    <Filter
                        iconFilter={false}
                        color="action"
                        borderColor="action"
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setFiltro(e.target.value)
                        }
                    />
                </BoxFilter>
                <Box width="202px">
                    <Link to="/sistema/novo">
                        <Button title="Adicionar USUÁRIO" />
                    </Link>
                </Box>
            </Flex>
        </ContainerHeader>
    );
}
