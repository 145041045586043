import styled from "styled-components";

export const ContainerHeader = styled.div`
    padding-bottom: 24px;
    border-bottom: 1px solid #ffffff1a;
`;

export const BoxFilter = styled.div`
    input {
        background: transparent;
        color: ${({ theme }) => theme.colors.white};
    }
`;

export const Container = styled.div`
    .loader {
        height: calc(100% - 115px - 76px - 100px);
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const PaginacaoStyles = styled.div`
    display: flex;
    align-items: center;
    button {
        width: 30px;
        height: 30px;
        margin-right: 20px;
    }
`;
