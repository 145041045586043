import { Column } from "react-table";
import { UserTable } from "./types";

export const column: Column<UserTable>[] = [
    {
        Header: "Nome",
        accessor: "name",
        width: 110,
        maxWidth: 110,
    },
    {
        Header: "Login",
        accessor: "email",
        width: 120,
        maxWidth: 120,
    },
    {
        Header: "Perfil",
        accessor: "perfil",
        width: 75,
        maxWidth: 75,
    },
    {
        Header: "Case",
        accessor: "case",
        width: 75,
        maxWidth: 75,
    },
    {
        Header: "Ações",
        accessor: "acoes",
        width: 24,
        maxWidth: 24,
    },
];
