import { Box } from "aplication-yetz";
import { useState } from "react";
import { useCase } from "../../hook/UseCase";
import { Option } from "../Select/types";
import { Menu } from "../../components/Menu";
import { Header } from "./header";
import { SubHeader } from "./subHeader";
import { TableAvaliacao } from "./table";
import { Modal } from "../Modal";
import { BoxTexto, BoxNotificacao } from "../BoxNotificacao";
import imgSucesso from "../../assets/sistema/sucesso.svg";

interface FiltroOptions extends Option {
    tipo: string;
}

export function Banca() {
    const [filtro, setFilro] = useState("");
    const [value, setValues] = useState<FiltroOptions[]>([]);
    const { descriptionCase, modal, success, closeModal } = useCase();

    const sucesso = {
        img: imgSucesso,
        title: "Case avaliado com sucesso!",
        onClick: onClick,
    };

    function onClick() {
        closeModal();
    }

    function clickOutModal(e: React.MouseEvent<HTMLElement>) {
        const element = (e.target as HTMLElement).dataset;
        if (element?.closemodal) {
            closeModal();
        }
    }

    return (
        <>
            <Menu />
            <div className="content">
                <Header setFiltro={setFilro} />
                <Box pt="55px">
                    <SubHeader setValues={setValues} value={value} />
                </Box>

                <TableAvaliacao filtro={filtro} select={value} />
            </div>

            <Modal
                component={BoxTexto}
                active={modal}
                onClick={clickOutModal}
                rest={{ ...descriptionCase, onClick }}
            />
            <Modal
                component={BoxNotificacao}
                rest={sucesso}
                onClick={clickOutModal}
                active={success}
            />
        </>
    );
}
