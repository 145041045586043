import { Modal } from "../Modal";
import { FormLogin } from "./FormLogin";
import { BoxNotificacao } from "../BoxNotificacao";
import { useLogin } from "../../hook/UseLogin";
import { Container, LogoContainer } from "./styles";

import logoImg from "../../assets/sistema/logo-login.svg";
import cadeadoImg from "../../assets/sistema/cadeado-vermelh.svg";

export function Login() {
    const { error, closeModal } = useLogin();

    const props = {
        img: cadeadoImg,
        title: "Dados incorretos, tente novamente",
    };

    function clickOutModal(e: React.MouseEvent<HTMLElement>) {
        const element = (e.target as HTMLElement).dataset;
        if (element?.closemodal) {
            closeModal();
        }
    }

    return (
        <>
            <Container>
                <FormLogin />
                <LogoContainer>
                    <img src={logoImg} alt="" />
                </LogoContainer>
            </Container>

            <Modal
                component={BoxNotificacao}
                onClick={clickOutModal}
                active={error}
                rest={props}
            />
        </>
    );
}
