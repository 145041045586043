import { Column } from "react-table";
import { Data } from "./types";

export const column: Column<Data>[] = [
    {
        Header: "EPS",
        accessor: "eps",
        width: 240,
        minWidth: 240,
    },
    {
        Header: "Negócio",
        accessor: "negocio",
        width: 100,
        minWidth: 100,
    },
    {
        Header: "Nível",
        accessor: "nivel",
        width: 150,
        minWidth: 150,
    },
    {
        Header: "Pilar",
        accessor: "pilar",
        width: 150,
        minWidth: 150,
    },
    {
        Header: "Título",
        accessor: "titulo",
        width: 415,
        minWidth: 415,
    },
    {
        Header: "Ler Case",
        accessor: "lerCase",
        width: 70,
        minWidth: 70,
    },
    {
        Header: "Editar",
        accessor: "editar",
        width: 70,
        minWidth: 70,
    },
    {
        Header: "Apagar",
        accessor: "apagar",
        width: 70,
        minWidth: 70,
    },
    {
        Header: "Motivo",
        accessor: "motivo",
        width: 70,
        minWidth: 70,
    },
];
