import { Table, Text, Box } from "aplication-yetz";
import { Link } from "react-router-dom";
import { filtroUsuarios } from "../../util/helper";
import { useUser } from "../../hook/UserContext";
import { NomeUser } from "../../components/NomeUser";
import { Loader } from "../Loader";
import { column } from "./config";
import { PaginacaoUser } from "../Paginacao";
import { User, UserTable } from "./types";

import editarImg from "../../assets/sistema/editar.svg";

import { Container } from "./styles";
import { useEffect, useState } from "react";

interface TabelaProps {
    filtro: string;
    perfil?: string;
}

export function Tabela(props: TabelaProps) {
    const { filtro, perfil } = props;
    const { users, loading, paginacao } = useUser();
    const [arrUser, setArrUser] = useState<User[]>([]);
    const [currentPage, setSurrentPage] = useState<number>();
    const [lastPage, setLastPage] = useState<number>();

    const filtroArr = filtroUsuarios(arrUser, filtro, perfil);

    const usersData: UserTable[] = filtroArr.map((arr) => {
        const { name, email, perfil, id } = arr;

        return {
            name: <NomeUser name={name} />,
            email: <Text color="white">{email}</Text>,
            perfil: <Text color="white">{perfil}</Text>,
            case: <Text color="white">2/20</Text>,
            acoes: (
                <Box ml="auto" width="24px">
                    <Link to={{ pathname: `/sistema/usuarios/editar/${id}` }}>
                        <img src={editarImg} alt="" />
                    </Link>
                </Box>
            ),
        };
    });

    useEffect(() => {
        if (users?.current_page && users.last_page) {
            setSurrentPage(users.current_page);
            setLastPage(users.last_page);
        }
    }, [users?.current_page, users?.last_page]);

    useEffect(() => {
        if (users?.data) {
            setArrUser(
                users.data.map((arr) => {
                    const { name, email, roles, id } = arr;

                    return {
                        name,
                        email,
                        perfil: roles[0].name,
                        case: "2/20",
                        acoes: "",
                        id,
                    };
                })
            );
        }
    }, [users?.data]);

    function paginacaoUser(id: number) {
        paginacao(id);
    }

    return (
        <Container>
            {!loading ? (
                <>
                    <Table columns={column} data={usersData} />

                    <PaginacaoUser
                        currentPage={currentPage}
                        lastPage={lastPage}
                        paginacao={paginacaoUser}
                    />
                </>
            ) : (
                <div className="loader">
                    <Loader width={200} height={200} />
                </div>
            )}
        </Container>
    );
}
