import styled from "styled-components";

export const Container = styled.div`
    .container-itens {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        bottom: -20px;
        z-index: 30;
        span {
            background-color: #19ffff;
            height: 5px;
            width: 5px;
            border-radius: 50%;
            position: relative;
        }
    }

    .container-imgs {
        display: flex;
        align-items: center;
        justify-content: space-between;
        img {
            &:nth-child(1) {
                position: relative;
                left: -15px;
            }
            &:nth-child(2) {
                position: relative;
                left: -10px;
            }
            &:nth-child(3) {
                position: relative;
                left: -2px;
            }
            &:nth-child(4) {
                position: relative;
                left: 3px;
            }

            &:nth-child(5) {
                position: relative;
                left: 8px;
            }

            &:nth-child(6) {
                position: relative;
                left: 20px;
            }
        }
    }
`;
