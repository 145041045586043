import { Text } from "aplication-yetz";
import { useEffect, useState } from "react";
import { Button } from "../Button";

import { PaginacaoStyles } from "./styles";

interface PaginacaoProps {
    currentPage?: number;
    lastPage?: number;
    paginacao: (id: number) => void;
}

export function PaginacaoUser(props: PaginacaoProps) {
    const { paginacao } = props;
    const [disablePrev, setDisablePrev] = useState<boolean>(false);
    const [disableNext, setDisableNext] = useState<boolean>(false);
    const [currentPage, setSurrentPage] = useState<number>();
    const [lastPage, setLastPage] = useState<number>();

    useEffect(() => {
        if (props?.currentPage && props.lastPage) {
            setSurrentPage(props.currentPage);
            setLastPage(props.lastPage);
        }
    }, [props?.currentPage, props?.lastPage]);

    function prev(value: number) {
        if (!value) {
            setDisablePrev(true);
        } else {
            setDisableNext(false);
            setDisablePrev(false);
            paginacao(value);
        }
    }

    function next(value: number) {
        if (lastPage) {
            if (value <= lastPage) {
                setDisableNext(false);
                setDisablePrev(false);
                paginacao(value);
            } else {
                setDisableNext(true);
            }
        }
    }

    return (
        <PaginacaoStyles>
            {lastPage && lastPage > 1 ? (
                <>
                    <Button
                        title="<"
                        onClick={() => {
                            if (currentPage) {
                                prev(currentPage - 1);
                            }
                        }}
                        disabled={disablePrev}
                    />
                    <Button
                        title=">"
                        onClick={() => {
                            if (currentPage) {
                                next(currentPage + 1);
                            }
                        }}
                        disabled={disableNext}
                    />
                    <Text color="white">
                        Pagina <strong>{currentPage}</strong> de{" "}
                        {lastPage ? lastPage : ""}
                    </Text>{" "}
                </>
            ) : null}
        </PaginacaoStyles>
    );
}
