import { HeaderStyles } from "./styles";

interface HeaderProps {
    pilar?: boolean;
    vincular?: boolean;
    case?: boolean;
}

export function Header(props: HeaderProps) {
    return (
        <HeaderStyles>
            <li className={`${props.pilar ? "active" : ""}`}>
                <span>Pilar</span>
            </li>
            <li className={`${props.vincular ? "active" : ""}`}>
                <span>VINCULAR</span>
            </li>
            <li className={`${props.case ? "active" : ""}`}>
                <span>CASE</span>
            </li>
        </HeaderStyles>
    );
}
