import { api } from "../services/api";
import { useHistory } from "react-router-dom";
import React, { createContext, useContext, useState } from "react";

interface UseloginProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

interface ValuesLogin {
    email: string;
    password: string;
}

export interface localStoreProps {
    token: string;
    user: string;
    id: number;
    nivel: string;
}

interface ResponseData {
    token: string;
    id: number;
    usuario: string;
    roles: [
        {
            id: number;
            name: string;
            pivot: {
                model_id: number;
            };
        }
    ];
}

interface UseLoginData {
    loading: boolean;
    error: boolean;

    closeModal: () => void;
    login: (values: ValuesLogin) => void;
}

const UseLoginContext = createContext<UseLoginData>({} as UseLoginData);

export function UseloginProvider(props: UseloginProviderProps) {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const history = useHistory();

    async function login(values: ValuesLogin) {
        setLoading(true);
        try {
            const { data } = await api.post<ResponseData>("/login", {
                ...values,
            });

            const user: localStoreProps = {
                user: data.usuario,
                token: data.token,
                id: data.roles[0].pivot.model_id,
                nivel: data.roles[0].name,
            };

            localStorage.setItem("user", JSON.stringify(user));
            history.push("/sistema/case");
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    function closeModal() {
        setError(false);
    }

    const { children } = props;

    return (
        <UseLoginContext.Provider value={{ closeModal, loading, error, login }}>
            {children}
        </UseLoginContext.Provider>
    );
}

export function useLogin() {
    const context = useContext(UseLoginContext);
    return context;
}
