import { memo } from "react";
import { Route, Redirect } from "react-router-dom";
import { getToken } from "../../util/helper";
import { setToken } from "../../services/api";
import { routesProps } from "../../routes/types";

function RouteWrapper(props: routesProps) {
    const { isPrivate, path } = props;

    const token = getToken();

    if (token) {
        setToken();
    }

    if (isPrivate && !token) {
        return <Redirect to="/" from={path} />;
    }

    if (path === "*") {
        return <Redirect to="/sistema/case" from={path} />;
    }

    if (path === "/" && token) {
        return <Redirect to="/sistema/case" from={path} />;
    }

    return <Route {...props} />;
}

export default memo(RouteWrapper);
