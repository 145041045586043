import { useEffect, useState } from "react";
import { useCase } from "../../hook/UseCase";
import { Text } from "aplication-yetz";
import { Filtro } from "../Select";
import { Option } from "../Select/types";

import { HeaderTableStyles } from "./styles";

interface FiltroOptions extends Option {
    tipo: string;
}

interface HeaderTableProps {
    setValues: (values: FiltroOptions[]) => void;
    value?: FiltroOptions[];
}

export function HeaderTable(props: HeaderTableProps) {
    const { setValues, value } = props;
    const { relationsUser } = useCase();
    const [EPS, setEPS] = useState<Option[]>();
    const [negocios, setnegocios] = useState<Option[]>();
    const [nivel, setnivel] = useState<Option[]>();
    const [pilar, setpilar] = useState<Option[]>();

    useEffect(() => {
        if (relationsUser.length) {
            const negocio: Option[] = relationsUser.map((item) => {
                const { meta_relations } = item;
                const { eps } = meta_relations;
                const { id, name } = eps;

                return { label: name, value: id };
            });

            const eps: Option[] = Object.values(
                negocio.reduce(
                    (acc, cur) => Object.assign(acc, { [cur.value]: cur }),
                    {}
                )
            );

            setEPS(eps);
        }
    }, [relationsUser]);

    useEffect(() => {
        if (relationsUser.length) {
            const negocio: Option[] = relationsUser.map((item) => {
                const { meta_relations } = item;
                const { negocio } = meta_relations;
                const { id, name } = negocio;

                return { label: name, value: id };
            });

            const negocios: Option[] = Object.values(
                negocio.reduce(
                    (acc, cur) => Object.assign(acc, { [cur.value]: cur }),
                    {}
                )
            );

            setnegocios(negocios);
        }
    }, [relationsUser]);

    useEffect(() => {
        if (relationsUser.length) {
            const nivel: Option[] = relationsUser.map((item) => {
                const { meta_relations } = item;
                const { nivel } = meta_relations;
                const { id, name } = nivel;

                return { label: name, value: id };
            });

            const niveis: Option[] = Object.values(
                nivel.reduce(
                    (acc, cur) => Object.assign(acc, { [cur.value]: cur }),
                    {}
                )
            );

            setnivel(niveis);
        }
    }, [relationsUser]);

    useEffect(() => {
        if (relationsUser.length) {
            const pilar: Option[] = relationsUser.map((item) => {
                const { meta_relations } = item;
                const { pilar } = meta_relations;
                const { id, name } = pilar;

                return { label: name, value: id };
            });

            const pilares: Option[] = Object.values(
                pilar.reduce(
                    (acc, cur) => Object.assign(acc, { [cur.value]: cur }),
                    {}
                )
            );

            setpilar(pilares);
        }
    }, [relationsUser]);

    return (
        <HeaderTableStyles>
            <Text fontSize="mdl" color="white" bold={true}>
                Todos Cases
            </Text>

            <div className="filtros">
                <div className="filtro">
                    <Filtro
                        options={EPS ? EPS : []}
                        placeholder="eps"
                        onChange={(data) => {
                            if (data && value) {
                                setValues([
                                    ...value.filter(
                                        (item) => item.tipo !== "eps"
                                    ),
                                    { ...data, tipo: "eps" },
                                ]);
                            } else {
                                if (value) {
                                    setValues(
                                        value.filter(
                                            (item) => item.tipo !== "eps"
                                        )
                                    );
                                }
                            }
                        }}
                        width={248}
                    />
                </div>
                <div className="filtro">
                    <Filtro
                        options={negocios ? negocios : []}
                        placeholder="Negócio"
                        onChange={(data) => {
                            if (data && value) {
                                setValues([
                                    ...value.filter(
                                        (item) => item.tipo !== "negocio"
                                    ),
                                    { ...data, tipo: "negocio" },
                                ]);
                            } else {
                                if (value) {
                                    setValues(
                                        value.filter(
                                            (item) => item.tipo !== "negocio"
                                        )
                                    );
                                }
                            }
                        }}
                        width={248}
                    />
                </div>
                <div className="filtro">
                    <Filtro
                        options={nivel ? nivel : []}
                        placeholder="Nível"
                        onChange={(data) => {
                            if (data && value) {
                                setValues([
                                    ...value.filter(
                                        (item) => item.tipo !== "nivel"
                                    ),
                                    { ...data, tipo: "nivel" },
                                ]);
                            } else {
                                if (value) {
                                    setValues(
                                        value.filter(
                                            (item) => item.tipo !== "nivel"
                                        )
                                    );
                                }
                            }
                        }}
                        width={248}
                    />
                </div>
                <div className="filtro">
                    <Filtro
                        options={pilar ? pilar : []}
                        placeholder="Pilar"
                        onChange={(data) => {
                            if (data && value) {
                                setValues([
                                    ...value.filter(
                                        (item) => item.tipo !== "pilar"
                                    ),
                                    { ...data, tipo: "pilar" },
                                ]);
                            } else {
                                if (value) {
                                    setValues(
                                        value.filter(
                                            (item) => item.tipo !== "pilar"
                                        )
                                    );
                                }
                            }
                        }}
                        width={248}
                    />
                </div>
            </div>
        </HeaderTableStyles>
    );
}
