import { useState } from "react";
import { Text, Flex, Box } from "aplication-yetz";
import { Button, Cancelar } from "../Button";
import { Container, Excluir, Detalhe } from "./styles";
import { convertDatedbForUser } from "../../util/helper";

import closeModalImg from "../../assets/sistema/close-modal.svg";

interface BoxNoticacaoProps {
    rest: {
        img: string;
        title: string;
        width?: number;
        onClick?: () => void;
        closeModal?: () => void;

        motivo?: boolean;
        setValueMotivo?: (value: string) => void;
    };
}

interface BoxTextoProps {
    rest: {
        titulo: string;
        data_ligacao: string;
        hora_ligacao: string;
        descricao: string;
        atitude?: string;
        monitor?: string;
        onClick: () => void;
    };
}

export function BoxNotificacao(props: BoxNoticacaoProps) {
    const { rest } = props;

    return (
        <Container width={rest.width}>
            <img src={rest.img} alt="" />

            {props.rest?.onClick ? (
                <div
                    className="close-notificacao"
                    onClick={() => {
                        if (props.rest?.onClick) {
                            props.rest.onClick();
                        }
                    }}
                >
                    <img src={closeModalImg} alt="" />
                </div>
            ) : null}

            <Text as="p" fontWeight="600" fontSize="mdl" color="white">
                {rest.title}
            </Text>
        </Container>
    );
}

export function BoxExclusao(props: BoxNoticacaoProps) {
    const { rest } = props;
    const [value, setValue] = useState("");
    const [errorValue, setErrorValue] = useState(false);

    return (
        <Excluir width={rest.width}>
            <Box>
                <img src={rest.img} alt="" />
            </Box>

            <Text as="p" fontWeight="600" fontSize="mdl" color="white">
                {rest.title}
            </Text>

            {rest.motivo ? (
                <Box mt="32px" width="100%">
                    <div className="container-input">
                        <textarea
                            rows={5}
                            placeholder="Motivo exclusão"
                            onChange={(e) => {
                                if (e.target.value !== "") {
                                    setErrorValue(false);
                                }
                                if (rest.setValueMotivo) {
                                    setValue(e.target.value);
                                    rest.setValueMotivo(e.target.value);
                                }
                            }}
                        />
                        {errorValue ? (
                            <small>Campo obrigatório</small>
                        ) : (
                            <small></small>
                        )}
                    </div>
                </Box>
            ) : null}

            <Flex width="205px" mt="32px" justifyContent="space-between">
                <Box width="93px">
                    <Cancelar
                        title="Não"
                        onClick={() => {
                            if (rest?.closeModal) {
                                rest.closeModal();
                                setErrorValue(false);
                            }
                        }}
                    />
                </Box>

                <Box width="93px">
                    <Button
                        title="Sim"
                        onClick={() => {
                            if (rest?.motivo === true && value !== "") {
                                if (rest?.onClick) {
                                    rest.onClick();
                                }
                                return;
                            }

                            if (rest?.motivo === true && value === "") {
                                if (rest?.onClick) {
                                    setErrorValue(true);
                                }
                                return;
                            }

                            if (rest?.onClick) {
                                rest.onClick();
                            }
                        }}
                    />
                </Box>
            </Flex>
        </Excluir>
    );
}

export function BoxTexto(props: BoxTextoProps) {
    return (
        <Detalhe width={899}>
            <Flex justifyContent="space-between" mb="42px">
                <Text fontSize="mdl" bold={true} color="white">
                    {props.rest?.titulo}
                </Text>

                <div
                    className="close"
                    onClick={() => {
                        if (props.rest?.onClick) {
                            props.rest.onClick();
                        }
                    }}
                >
                    <img src={closeModalImg} alt="" />
                </div>
            </Flex>

            <Box mb="1.5rem">
                <Text fontSize="sm" bold={true} color="white">
                    {props.rest?.monitor
                        ? `Monitor: ${props.rest?.monitor}`
                        : ""}
                </Text>
            </Box>

            <Flex mb="1.5rem">
                <Text fontSize="sm" bold={true} color="white">
                    {props.rest?.titulo
                        ? convertDatedbForUser(props.rest.data_ligacao)
                        : ""}
                </Text>
                <Text ml="39px" fontSize="sm" bold={true} color="white">
                    {props.rest?.hora_ligacao}
                </Text>
            </Flex>

            <Box mb="1.5rem">
                <Text fontSize="sm" bold={true} color="white">
                    {props.rest?.atitude}
                </Text>
            </Box>

            <Box mb="39px">
                <hr />
            </Box>

            <div className="content-text">
                {props.rest.descricao ? (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: props.rest.descricao,
                        }}
                    ></div>
                ) : (
                    <></>
                )}
            </div>
        </Detalhe>
    );
}
