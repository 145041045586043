import { Flex, Heading } from "aplication-yetz";
import { useUser } from "../../hook/UserContext";
import { Filtro } from "../../components/Select";
import { Option } from "../../components/Select/types";

interface SubHeaderProps {
    setPerfil: (value: Option) => void;
}

export function SubHeader(props: SubHeaderProps) {
    const { setPerfil } = props;
    const { perfil } = useUser();

    return (
        <>
            <Flex pt="3.625rem" justifyContent="space-between">
                <Heading as="h2" color="white">
                    Todos Usuários
                </Heading>

                <Filtro onChange={setPerfil} options={perfil} />
            </Flex>
        </>
    );
}
