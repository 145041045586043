import { Cases } from "../../components/Case";
import { UseCaseProvider } from "../../hook/UseCase";

export function Case() {
    return (
        <>
            <UseCaseProvider>
                <Cases />
            </UseCaseProvider>
        </>
    );
}
