import { Container } from "./styles";
import imgLogo from "../../assets/sistema/lolgo-menu.svg";
export function Menu() {
    function sair() {
        localStorage.clear();
        window.location.reload();
    }

    return (
        <Container>
            <img src={imgLogo} alt="" />

            <button onClick={sair}>Sair</button>
        </Container>
    );
}
