import styled from "styled-components";

interface StyledProps {
    width?: number;
}

export const Container = styled.div<StyledProps>`
    display: grid;
    grid-template-columns: 717px 1fr;
    overflow-y: scroll;
    overflow-x: hidden;
    height: auto;
    min-height: 100vh;
    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
        width: 5px;
    }

    &::-webkit-scrollbar:horizontal {
        height: 12px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(89, 92, 118, 0.5);
    }

    &::-webkit-scrollbar-track {
        background-color: rgba(255, 255, 255, 0.1);
    }

    .esq {
        background: ${({ theme }) => theme.colors.primary};
        width: 100%;
        height: 100%;
        padding-top: 2.75rem;
        padding-right: 4.563rem;
        padding-bottom: 64px;
        img {
            margin-left: auto;
            display: block;
        }
    }

    .dir {
        padding-top: 4.25rem;
        padding-left: 5.75rem;
        background: ${({ theme }) => theme.colors.fundoBase};
        padding-bottom: 64px;
        overflow: hidden;
        .header {
            display: flex;
            align-items: center;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            padding-bottom: 32px;
            margin-bottom: 40px;
            width: ${({ width }) => (width ? width + "px" : "100%")};
            cursor: pointer;
        }
    }
`;
