import * as Yup from "yup";

export interface MyFormValues {
    email: string;
    password: string;
}

export const initialValues: MyFormValues = {
    email: "",
    password: "",
};

export const validation = Yup.object().shape({
    email: Yup.string()
        .email("Insira um E-mail válido")
        .required("E-mail e um campo obrigatório"),
    password: Yup.string().required("Campo Obrigatório"),
});
