import * as React from "react";
import { Range, getTrackBackground } from "react-range";
import { useCase } from "../../hook/UseCase";
import { Container } from "./styles";
import rangeImg from "../../assets/sistema/icone-range.svg";
import img01 from "../../assets/sistema/avaliacao/01.svg";
import img02 from "../../assets/sistema/avaliacao/02.svg";
import img03 from "../../assets/sistema/avaliacao/03.svg";
import img04 from "../../assets/sistema/avaliacao/04.svg";
import img05 from "../../assets/sistema/avaliacao/05.svg";
import img06 from "../../assets/sistema/avaliacao/06.svg";

const STEP = 0.1;
const MIN = 0;
const MAX = 10;

interface AvaliacaoProps {
    rtl: boolean;
    id: number;
    nota: number;
    disabled?: boolean;
}

export function Avaliacao(props: AvaliacaoProps) {
    const { id, nota, disabled = false } = props;
    const [values, setValues] = React.useState([nota]);
    const { caseAvaliacao } = useCase();

    return (
        <Container>
            <div className="container-imgs">
                <img src={img01} alt="" />
                <img src={img02} alt="" />
                <img src={img03} alt="" />
                <img src={img04} alt="" />
                <img src={img05} alt="" />
                <img src={img06} alt="" />
            </div>

            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    pointerEvents: disabled ? "none" : "all",
                }}
            >
                <Range
                    values={values}
                    step={STEP}
                    min={MIN}
                    max={MAX}
                    onChange={(values: any) => {
                        if (!disabled) {
                            setValues(values);
                        }
                    }}
                    onFinalChange={() =>
                        caseAvaliacao({
                            case_id: id,
                            nota: values[0],
                        })
                    }
                    renderTrack={({ props, children }: any) => (
                        <div
                            style={{
                                ...props.style,
                                height: "36px",
                                display: "flex",
                                width: "100%",
                                pointerEvents: disabled ? "none" : "all",
                            }}
                        >
                            <div
                                ref={props.ref}
                                style={{
                                    height: "2px",
                                    width: "100%",
                                    borderRadius: "4px",
                                    pointerEvents: disabled ? "none" : "all",
                                    background: getTrackBackground({
                                        values: values,
                                        colors: ["#19FFFF", "#203742"],
                                        min: MIN,
                                        max: MAX,
                                    }),
                                    alignSelf: "center",
                                }}
                            >
                                {children}
                            </div>
                        </div>
                    )}
                    renderThumb={({ props, isDragged }: any) => (
                        <div
                            {...props}
                            style={{
                                ...props.style,
                                height: "18px",
                                width: "14px",
                                borderRadius: "4px",
                                backgroundColor: "transparent",
                                marginLeft: "30px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "relative",
                                pointerEvents: disabled ? "none" : "all",
                            }}
                        >
                            <img
                                src={rangeImg}
                                alt=""
                                style={{
                                    marginLeft: "10px",
                                    pointerEvents: "none",
                                }}
                            />
                            <div
                                style={{
                                    height: "16px",
                                    width: "5px",
                                    pointerEvents: disabled ? "none" : "all",
                                    backgroundColor: isDragged
                                        ? "#19FFFF"
                                        : "#203742",
                                }}
                            />
                        </div>
                    )}
                />
            </div>
        </Container>
    );
}
