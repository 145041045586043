import { ChangeEvent } from "react";
import { Text, Flex, Filter, Box } from "aplication-yetz";
import { CasesData } from "../../hook/UseCase";
import { Link } from "react-router-dom";
import { Button } from "../../components/Button";
import { NomeUser } from "../../components/NomeUser";
import { getLocalStore, convertDatedbForUser } from "../../util/helper";

import { BoxFilter, ContainerHeader } from "./styles";

interface HeaderProps {
    setFiltro: (e: string) => void;
    cases: CasesData[];
    limitCase: boolean;
}

export function Header(props: HeaderProps) {
    const { setFiltro, cases = [], limitCase } = props;
    const itens = getLocalStore();

    const lastItem = cases[cases.length - 1];

    return (
        <ContainerHeader>
            <Flex justifyContent="space-between">
                <Box width="240px">
                    <NomeUser name={itens ? itens.user : ""} />
                </Box>

                <BoxFilter>
                    <Filter
                        iconFilter={false}
                        color="action"
                        borderColor="action"
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setFiltro(e.target.value)
                        }
                    />
                </BoxFilter>
                <Flex alignItems="center">
                    <Text color="gray700" mr="48px">
                        Último cadastro -{" "}
                        {lastItem?.data_ligacao
                            ? convertDatedbForUser(lastItem.data_ligacao)
                            : ""}
                    </Text>

                    {!limitCase ? (
                        <Box width="185px">
                            <Link to="/sistema/novo">
                                <div className="btn">
                                    <Button title="CADASTRAR CASE" />
                                </div>
                            </Link>
                        </Box>
                    ) : (
                        <></>
                    )}
                </Flex>
            </Flex>
        </ContainerHeader>
    );
}
