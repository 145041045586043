import { Box, Text } from "aplication-yetz";
import { useEffect, useState } from "react";
import { useCase } from "../../hook/UseCase";
import { Option } from "../Select/types";
import { Menu } from "../Menu";
import { Header } from "./header";
import { SubHeader } from "./subHeader";
import { TableAvaliacao } from "./table";
import { Modal } from "../Modal";
import { BoxTexto, BoxNotificacao, BoxExclusao } from "../BoxNotificacao";

import imgSucesso from "../../assets/sistema/sucesso.svg";
import imgExcluir from "../../assets/sistema/lixeira.svg";
import imgRestore from "../../assets/sistema/avaliacao/restore.svg";
import imgCloseModal from "../../assets/sistema/close-modal.svg";

const sucesso = {
    img: imgSucesso,
    title: "Sucesso!",
};

interface FiltroOptions extends Option {
    tipo: string;
}

export function Engajamento() {
    const [filtro, setFilro] = useState("");
    const [modalExcluirCase, setModalExcluirCase] = useState<boolean>(false);
    const [hasRestoreCase, setHasRestoreCase] = useState<boolean>(false);
    const [value, setValues] = useState<FiltroOptions[]>([]);
    const [valueMotivo, setValueMotivo] = useState<string>("");
    const {
        descriptionCase,
        modal,
        toggleModal,
        desativarCase,
        restaurarCase,
        caseId,
        setCaseId,
        success,
        countCases,
        modalReprova,
        motivoReprova,
    } = useCase();

    const excluir = {
        img: hasRestoreCase ? imgRestore : imgExcluir,
        title: (
            <Text as="p" fontWeight="600" fontSize="mdl" color="white">
                Tem certeza que deseja <br />{" "}
                {hasRestoreCase ? "restaurar" : "excluir"} esse case?
            </Text>
        ),
        motivo: !hasRestoreCase ? true : false,
        setValueMotivo: setValueMotivo,
        width: "522px",
        onClick: hasRestoreCase
            ? restaurarCase
            : () => desativarCase(valueMotivo),
        closeModal: closeModal,
    };

    const reprova = {
        img: imgCloseModal,
        titulo: "Observações",
        descricao: `<p>${motivoReprova}</p>`,
    };

    function onClick() {
        toggleModal(false);
    }

    function clickOutModal(e: React.MouseEvent<HTMLElement>) {
        const element = (e.target as HTMLElement).dataset;
        if (element?.closemodal) {
            toggleModal(false);
            setModalExcluirCase(false);
        }
    }

    function closeModal() {
        setModalExcluirCase(false);
        setCaseId(undefined);
    }

    useEffect(() => {
        if (caseId) {
            setModalExcluirCase(true);
        } else {
            setModalExcluirCase(false);
        }
    }, [caseId]);

    return (
        <>
            <Menu />
            <div className="content">
                <Header setFiltro={setFilro} cases={countCases} />
                <Box pt="55px">
                    <SubHeader setValues={setValues} value={value} />
                </Box>

                <TableAvaliacao
                    filtro={filtro}
                    select={value}
                    setcaseId={setCaseId}
                    setHasRestoreCase={setHasRestoreCase}
                />
            </div>

            <Modal
                component={BoxTexto}
                active={modal}
                onClick={clickOutModal}
                rest={{ ...descriptionCase, onClick }}
            />

            <Modal
                component={BoxNotificacao}
                rest={sucesso}
                active={success}
                onClick={clickOutModal}
            />

            <Modal
                component={BoxExclusao}
                rest={excluir}
                active={modalExcluirCase}
            />

            <Modal
                component={BoxTexto}
                active={modalReprova}
                onClick={clickOutModal}
                rest={{ ...reprova, onClick }}
            />
        </>
    );
}
