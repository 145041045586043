import * as Yup from "yup";

export interface PilarValues {
    titulo: string;
    monitor: string | null;
    eps: number;
    negocios: number;
    nivel: number;
    pilar: number;
}

export const initialValues: PilarValues = {
    titulo: "",
    monitor: "",
    eps: 0,
    negocios: 0,
    nivel: 0,
    pilar: 0,
};

export const validadtion = Yup.object().shape({
    titulo: Yup.string().required("Campo Obrigatório"),
    monitor: Yup.string(),
    eps: Yup.number().min(1, "Campo Obrigatório"),
    negocios: Yup.number().min(1, "Campo Obrigatório"),
    nivel: Yup.number().min(1, "Campo Obrigatório"),
    pilar: Yup.number().min(1, "Campo Obrigatório"),
});

export interface VincularValues {
    funcionario_id: number;
}

export const initialValuesVincular: VincularValues = {
    funcionario_id: 0,
};

export const validadtionVincular = Yup.object().shape({
    funcionario_id: Yup.number().required("Selecione um card"),
});

export interface CaseValues {
    dataTime: string;
    atitude_diferenciada: string;
    descricao: string;
}

export const initialValuesCase: CaseValues = {
    dataTime: "",
    atitude_diferenciada: "",
    descricao: "",
};

export const validadtionCase = Yup.object().shape({
    dataTime: Yup.string().required("Campo Obrigatório"),
    atitude_diferenciada: Yup.string().required("Campo Obrigatório"),
    descricao: Yup.string().required("Campo Obrigatório"),
});
