import { useEffect, useState } from "react";
import { Box, Flex, Text } from "aplication-yetz";
import { Formik, Form, Field, ErrorMessage, FormikProps } from "formik";
import { useHistory } from "react-router-dom";
import { useCase, CasesData } from "../../hook/UseCase";
import { Select } from "../Select";
import { Option } from "../Select/types";
import { Button, Cancelar } from "../Button";
import { Toggle } from "../Toggle";
import { initialValues, validadtion, PilarValues } from "./types";
import { BoxSelect } from "./styles";

interface PilarProps {
    caseById?: CasesData[];
}

export function Pilar(props: PilarProps) {
    const { caseById } = props;
    const hasEdit = caseById?.length;
    const history = useHistory();

    const { addPilar, updatePilar, EPS, relation } = useCase();
    const [values, setValues] = useState(initialValues);

    const [EPSSelectOptions, setSelectEPSOptions] = useState<Option>();
    const [negocioSelectOptions, setNegocioSelectEPSOptions] =
        useState<Option>();
    const [nivelSelectOptions, setNivelSelectEPSOptions] = useState<Option>();
    const [pilarSelectOptions, setPilarSelectEPSOptions] = useState<Option>();

    const [negociosOptions, setNegociosOptions] = useState<Option[]>([]);
    const [nivelOptions, setnivelOptions] = useState<Option[]>([]);
    const [pilarOptions, setpilarOptions] = useState<Option[]>([]);

    const [toggle, setToggle] = useState<boolean>(false);

    useEffect(() => {
        if (values.eps) {
            const negocioRelations = relation.filter((item) => {
                const { meta_relations } = item;
                const { eps } = meta_relations;

                if (eps.id === values.eps) {
                    return meta_relations.negocio;
                } else {
                    return false;
                }
            });

            if (negocioRelations.length) {
                const negocio: Option[] = negocioRelations.map((item) => {
                    const { meta_relations } = item;
                    const { negocio } = meta_relations;
                    const { id, name } = negocio;

                    return { label: name, value: id };
                });

                const negocioUniq: Option[] = Object.values(
                    negocio.reduce(
                        (acc, cur) => Object.assign(acc, { [cur.value]: cur }),
                        {}
                    )
                );

                setNegociosOptions(negocioUniq);
            }
        }
    }, [values.eps, relation]);

    useEffect(() => {
        if (values.negocios) {
            const nivelRelation = relation.filter((item) => {
                const { meta_relations } = item;
                const { negocio } = meta_relations;

                if (negocio.id === values.negocios) {
                    return meta_relations.nivel;
                } else {
                    return false;
                }
            });

            if (nivelRelation.length) {
                const negocio: Option[] = nivelRelation.map((item) => {
                    const { meta_relations } = item;
                    const { nivel } = meta_relations;
                    const { id, name } = nivel;

                    return { label: name, value: id };
                });

                const nivelUniq: Option[] = Object.values(
                    negocio.reduce(
                        (acc, cur) => Object.assign(acc, { [cur.value]: cur }),
                        {}
                    )
                );

                setnivelOptions(nivelUniq);
            }
        }
    }, [relation, values.negocios]);

    useEffect(() => {
        if (values.nivel) {
            const pilarRelation = relation.filter((item) => {
                const { meta_relations } = item;
                const { nivel } = meta_relations;

                if (nivel.id === values.nivel) {
                    return meta_relations.pilar;
                } else {
                    return false;
                }
            });

            if (pilarRelation.length) {
                const negocio: Option[] = pilarRelation.map((item) => {
                    const { meta_relations } = item;
                    const { pilar } = meta_relations;
                    const { id, name } = pilar;

                    return { label: name, value: id };
                });

                const pilarUniq: Option[] = Object.values(
                    negocio.reduce(
                        (acc, cur) => Object.assign(acc, { [cur.value]: cur }),
                        {}
                    )
                );

                setpilarOptions(pilarUniq);
            }
        }
    }, [relation, values.nivel]);

    useEffect(() => {
        if (caseById?.length) {
            const eps: Option[] = caseById.map((item) => {
                const { meta_relacao } = item;
                const { eps } = meta_relacao;
                const { id, name } = eps;

                return { label: name, value: id };
            });

            const negocio: Option[] = caseById.map((item) => {
                const { meta_relacao } = item;
                const { negocio } = meta_relacao;
                const { id, name } = negocio;

                return { label: name, value: id };
            });

            const nivel: Option[] = caseById.map((item) => {
                const { meta_relacao } = item;
                const { nivel } = meta_relacao;
                const { id, name } = nivel;

                return { label: name, value: id };
            });

            const pilar: Option[] = caseById.map((item) => {
                const { meta_relacao } = item;
                const { pilar } = meta_relacao;
                const { id, name } = pilar;

                return { label: name, value: id };
            });

            const { titulo, meta_relacao, monitor = "" } = caseById[0];

            const {
                eps: { id: eps_id },
            } = meta_relacao;
            const {
                negocio: { id: negocio_id },
            } = meta_relacao;
            const {
                nivel: { id: nivel_id },
            } = meta_relacao;
            const {
                pilar: { id: pilar_id },
            } = meta_relacao;

            setValues({
                titulo,
                eps: eps_id,
                negocios: negocio_id,
                nivel: nivel_id,
                pilar: pilar_id,
                monitor,
            });

            setSelectEPSOptions(eps[0]);
            setNegocioSelectEPSOptions(negocio[0]);
            setNivelSelectEPSOptions(nivel[0]);
            setPilarSelectEPSOptions(pilar[0]);
        }
    }, [caseById]);

    useEffect(() => {
        if (values.monitor) {
            setToggle(true);
        } else {
            setToggle(false);
        }
    }, [values.monitor]);

    function cancelar() {
        history.push("/sistema");
    }

    return (
        <Formik
            initialValues={values}
            enableReinitialize
            validationSchema={validadtion}
            onSubmit={(values, actions) => {
                if (caseById?.length) {
                    updatePilar(values);
                } else {
                    addPilar(values);
                }
            }}
        >
            {(props: FormikProps<PilarValues>) => {
                const { setFieldValue, values } = props;

                return (
                    <Form>
                        <div className="container-input">
                            <Field
                                type="text"
                                name="titulo"
                                placeholder="Título do Case"
                            />
                            <ErrorMessage component="small" name="titulo" />
                        </div>

                        <div className="container-input">
                            <Flex
                                justifyContent="space-between"
                                alignItems="center"
                                height="56px"
                            >
                                <Text color="white">
                                    Foi indicado pela monitoria?
                                </Text>

                                <Toggle
                                    active={toggle}
                                    onClick={() => setToggle(!toggle)}
                                />
                            </Flex>
                        </div>

                        <div
                            className={`container-input ${
                                !toggle ? "toggle-input " : ""
                            }`}
                        >
                            <Field
                                type="text"
                                name="monitor"
                                placeholder="Nome do monitor(a)"
                            />
                        </div>

                        <BoxSelect disable={hasEdit ? true : false}>
                            <Select
                                initalValue={EPSSelectOptions}
                                options={EPS}
                                placeholder="EPS"
                                onChange={(value) => {
                                    setValues({
                                        ...values,
                                        eps: value.value,
                                    });
                                    setFieldValue("eps", value.value);
                                }}
                            />
                            <ErrorMessage component="small" name="eps" />
                        </BoxSelect>
                        <BoxSelect disable={hasEdit ? true : false}>
                            <Select
                                initalValue={negocioSelectOptions}
                                options={negociosOptions}
                                placeholder="Selecione o Negócio"
                                onChange={(value) => {
                                    setValues({
                                        ...values,
                                        negocios: value.value,
                                    });
                                    setFieldValue("negocios", value.value);
                                }}
                            />
                            <ErrorMessage component="small" name="negocios" />
                        </BoxSelect>
                        <BoxSelect disable={hasEdit ? true : false}>
                            <Select
                                initalValue={nivelSelectOptions}
                                options={nivelOptions}
                                placeholder="Nível"
                                onChange={(value) => {
                                    setValues({
                                        ...values,
                                        nivel: value.value,
                                    });
                                    setFieldValue("nivel", value.value);
                                }}
                            />
                            <ErrorMessage component="small" name="nivel" />
                        </BoxSelect>
                        <BoxSelect disable={hasEdit ? true : false}>
                            <Select
                                initalValue={pilarSelectOptions}
                                options={pilarOptions}
                                placeholder="Pilar"
                                onChange={(value) => {
                                    setFieldValue("pilar", value.value);
                                }}
                            />
                            <ErrorMessage component="small" name="pilar" />
                        </BoxSelect>

                        <Flex
                            width="288px"
                            justifyContent="space-between"
                            mt="32px"
                        >
                            <Box width="136px">
                                <Cancelar
                                    title="cancelar"
                                    onClick={() => cancelar()}
                                />
                            </Box>
                            <Box width="136px">
                                <Button title="Avançar" />
                            </Box>
                        </Flex>
                    </Form>
                );
            }}
        </Formik>
    );
}
