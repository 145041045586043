import { Text, Flex, Box, Grid } from "aplication-yetz";
import { CardStyles } from "./styles";
import { Vinculos } from "./vincular";

interface VinculosProps extends Vinculos {
    selectItem: (values: number) => void;
}

export function Card(props: VinculosProps) {
    const {
        central,
        coordenador,
        nome,
        supervisor,
        cpf,
        active,
        selectItem,
        id,
    } = props;

    function formataCPF(cpf: string) {
        cpf = cpf.replace(/[^\d]/g, "");
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    }

    return (
        <CardStyles
            onClick={() => selectItem(id)}
            className={`${active ? "active" : ""}`}
        >
            <Flex mb="8px">
                <Text bold={true} color="white" as="p">
                    {nome}
                </Text>
            </Flex>

            <Grid gridTemplateColumns="370px 1fr 1fr 1fr">
                <Box>
                    <Text fontSize="sm" mb="8px" color="gray700">
                        Cpf
                    </Text>
                    <Text fontSize="sm" color="white">
                        {formataCPF(cpf)}
                    </Text>
                </Box>

                <Box>
                    <Text fontSize="sm" mb="8px" color="gray700">
                        Central
                    </Text>
                    <Text fontSize="sm" color="white">
                        {central}
                    </Text>
                </Box>

                <Box>
                    <Text fontSize="sm" mb="8px" color="gray700">
                        Supervisor
                    </Text>
                    <Text fontSize="sm" color="white">
                        {supervisor}
                    </Text>
                </Box>

                <Box>
                    <Text fontSize="sm" mb="8px" color="gray700">
                        Coordenador
                    </Text>
                    <Text fontSize="sm" color="white">
                        {coordenador}
                    </Text>
                </Box>
            </Grid>
        </CardStyles>
    );
}
