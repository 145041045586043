import { useState } from "react";
import { Flex, Text, Filter, Box, ButtonSystem } from "aplication-yetz";
import { Loader } from "../../components/Loader";
import { ChangeEvent } from "react";
import { NomeUser } from "../NomeUser";
import { getLocalStore } from "../../util/helper";
import { api } from "../../services/api";

import { BoxFilter, ContainerHeader } from "./styles";
import checkImg from "../../assets/sistema/check.svg";

interface HeaderProps {
    setFiltro: (e: string) => void;
    cases?: number;
}

export function Header(props: HeaderProps) {
    const { setFiltro, cases } = props;
    const [loading, setLoading] = useState(false);
    const itens = getLocalStore();

    async function exportar() {
        setLoading(true);

        const { data } = await api.get(`case/export`);

        if (data) {
            window.open(data.file_url);
        }

        setLoading(false);
    }

    return (
        <ContainerHeader>
            <Flex justifyContent="space-between">
                <Box width="240px">
                    <NomeUser name={itens ? itens.user : ""} />
                </Box>

                <BoxFilter>
                    <Filter
                        iconFilter={false}
                        color="action"
                        borderColor="action"
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setFiltro(e.target.value)
                        }
                    />
                </BoxFilter>
                <Flex alignItems="center">
                    <ButtonSystem
                        background="action"
                        onClick={() => exportar()}
                        mr="51px"
                    >
                        {loading ? (
                            <Loader height={20} width={20} />
                        ) : (
                            <Text color="fundoBase" bold={true}>
                                EXPORTAR CASES
                            </Text>
                        )}
                    </ButtonSystem>
                    <div className="case-avaliados">
                        <img src={checkImg} alt="" />

                        <p>Cases {cases}</p>
                    </div>
                </Flex>
            </Flex>
        </ContainerHeader>
    );
}
