import styled, { css } from "styled-components";
import checkImg from "../../assets/sistema/check-round.svg";

export const HeaderStyles = styled.ul`
    display: flex;
    justify-content: space-between;
    width: 520px;
    li {
        list-style: none;
        opacity: 0.2;
        pointer-events: none;
        position: relative;
        &:first-child {
            &:before {
                display: none;
            }
        }
        &::before {
            content: "";
            display: block;
            width: 50px;
            height: 1px;
            background-color: ${({ theme }) => theme.colors.sinalizacao};
            position: absolute;
            top: 50%;
            left: -75px;
            transform: translate(-50%, -50%);
        }
        &.active {
            opacity: 1;
            pointer-events: all;
        }
        span {
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.06em;
            text-transform: uppercase;
            color: ${({ theme }) => theme.colors.white};
            position: relative;
            padding-left: 30px;
            &:before {
                content: "";
                display: block;
                width: 20px;
                height: 20px;
                position: absolute;
                top: 50%;
                left: 0px;
                transform: translateY(-50%);
                -webkit-mask: url(${checkImg});
                -webkit-mask-repeat: no-repeat;
                background: ${({ theme }) => theme.colors.sinalizacao};
            }
        }
    }
`;

interface disable {
    disable: boolean;
}

export const Disabled = css`
    cursor: not-allowed;
    opacity: 0.5;
    > div {
        pointer-events: none;
    }
`;

export const BoxSelect = styled.div<disable>`
    margin-bottom: 16px;
    ${(props) => (props.disable ? Disabled : null)}
`;

export const CardStyles = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.line};
    padding: 16px 24px;
    transition: all 0.5s;
    cursor: pointer;
    margin-bottom: 8px;
    &:hover {
        border: 1px solid ${({ theme }) => theme.colors.action};
        transition: all 0.5s;
        p {
            color: ${({ theme }) => theme.colors.action};
            transition: all 0.5s;
        }
    }

    &.active {
        border: 1px solid ${({ theme }) => theme.colors.action};
        transition: all 0.5s;
        p {
            color: ${({ theme }) => theme.colors.action};
            transition: all 0.5s;
        }
    }
`;

export const Input = styled.div`
    width: 520px;
`;

export const Casess = styled.div`
    padding-top: 48px;
    position: relative;
    p {
        opacity: 0.75;
    }
    .container-case {
        position: absolute;
        top: 0px;
        left: 0px;
        opacity: 0;
        pointer-events: none;
        transition: all 0.5s;
        &.pilar {
            width: 520px;
        }

        &.card-case {
            width: 520px;
        }

        &.vincular {
            width: 1028px;
        }
        &.active {
            opacity: 1;
            pointer-events: all;
            position: static;
            transition: all 0.5s;
        }
    }
`;
