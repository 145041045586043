import styled, { css } from "styled-components";

interface StyledProps {
    upperCase?: boolean;
    loadingButton?: boolean;
}

export const Container = styled.button<StyledProps>`
    width: 100%;
    height: 51px;
    border: none;
    border-radius: 4px;

    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.colors.action};

    font-family: "Roboto" sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;
    text-transform: ${({ upperCase }) => (upperCase ? "uppercase" : "normal")};

    color: #252e41;
    cursor: pointer;

    ${(props) => (props.loadingButton ? loadind : "")}
    ${(props) => (props.disabled ? disabled : "")}
`;

export const loadind = css`
    cursor: not-allowed;
    opacity: 0.6;
`;

export const disabled = css`
    opacity: 0.6;
    cursor: not-allowed;
`;

export const Cancelar = styled.button<StyledProps>`
    width: 100%;
    height: 51px;
    border: none;
    border-radius: 4px;

    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.colors.transparent};
    border: 1px solid ${({ theme }) => theme.colors.action};

    font-family: "Roboto" sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;
    text-transform: ${({ upperCase }) => (upperCase ? "uppercase" : "normal")};

    color: ${({ theme }) => theme.colors.action};
    cursor: pointer;
`;
