import axios from "axios";
import { getToken } from "../util/helper";
const baseUrl = process.env.REACT_APP_BASE_HTTP;

export const api = axios.create({
    baseURL: baseUrl,
});

export const setToken = () => {
    api.interceptors.request.use(function (config) {
        config.headers.authorization = `Bearer ${getToken()}`;
        return config;
    });
};
