import styled from "styled-components";

export const Container = styled.div`
    width: 100vw;
    height: 100vh;
    background: ${({ theme }) => theme.colors.primary};
    display: grid;
    grid-template-columns: 872px 1fr;
`;

export const Formstyles = styled.div`
    width: 100%;
    height: 100%;
    padding-top: 12.688rem;
    margin-left: auto;
    margin-right: 140px;
    background: ${({ theme }) => theme.colors.fundoBase};
    .container-input {
        &.senha {
            input {
                padding-right: 65px;
            }
        }
    }
    .change-password {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        background-color: transparent;
        border: none;
        &.active {
            &:before {
                height: 30px;
                transition: all 0.2s;
            }
        }
        &:before {
            content: "";
            display: block;
            width: 2px;
            height: 0px;
            background-color: ${({ theme }) => theme.colors.gray700};
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            transition: all 0.2s;
        }
    }
    span {
        display: block;
        position: relative;
        top: -15px;
    }
`;

export const LogoContainer = styled.div`
    width: 100%;
    background: ${({ theme }) => theme.colors.primary};
    padding-top: 13.75rem;
    margin-left: 141px;
`;
