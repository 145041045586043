import { Login } from "../../components/Login";
import { UseloginProvider } from "../../hook/UseLogin";

export function PageLogin() {
    return (
        <UseloginProvider>
            <Login />
        </UseloginProvider>
    );
}
