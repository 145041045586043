import { Text, Flex, Box } from "aplication-yetz";

import { CardStyles } from "./styles";
import { arrCard } from "../Case";

export function Card(props: arrCard) {
    const { fazer_melhor, humanizar, resolver, title, negocio, nivel } = props;

    return (
        <CardStyles>
            <Flex justifyContent="space-between" alignItems="flex-end">
                <Box>
                    <Text
                        fontSize="mdl"
                        fontWeight="600"
                        color="white"
                        mb="12px"
                    >
                        {title}
                    </Text>
                    <Text fontSize="md" color="gray700" mb="8px">
                        {negocio}
                    </Text>
                    <Text fontSize="md" color="gray700" mb="8px">
                        {nivel}
                    </Text>
                </Box>

                <Box>
                    <Flex
                        width="226px"
                        mt="27px"
                        pb="10px"
                        justifyContent="space-between"
                    >
                        <span className="estrela">0{fazer_melhor}/03</span>
                        <span className="coracao">0{humanizar}/03</span>
                        <span className="diamante">0{resolver}/03</span>
                    </Flex>
                </Box>
            </Flex>
        </CardStyles>
    );
}
