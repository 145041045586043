import { BrowserRouter } from "react-router-dom";
import { routes } from "./routes";
import { SwitchRoutes } from "./components/SwitchRoutes";

import { GlobalStyle } from "./styles/global";
import Interceptor from "./services/interceptor";

import "draft-js/dist/Draft.css";

export const teste = () => {
    return <h1>olá</h1>;
};

export function App() {
    return (
        <div>
            <GlobalStyle />
            <Interceptor />
            <BrowserRouter>
                <SwitchRoutes routes={routes} />
            </BrowserRouter>
        </div>
    );
}
