import { Text } from "aplication-yetz";
import { useEffect, useState } from "react";
import { useCase } from "../../hook/UseCase";
import { Option } from "../Select/types";
import { Menu } from "../../components/Menu";
import { Header } from "./header";
import { SubHeader } from "./subHeader";
import { HeaderTable } from "./topTable";
import { TableCase } from "./table";
import { Modal } from "../Modal";
import { BoxTexto, BoxExclusao } from "../BoxNotificacao";
import imgExcluir from "../../assets/sistema/lixeira.svg";
import imgCloseModal from "../../assets/sistema/close-modal.svg";

interface FiltroOptions extends Option {
    tipo: string;
}

export interface arrCard {
    fazer_melhor: number;
    humanizar: number;
    resolver: number;
    title: string;
    nivel: string;
    negocio: string;
}

export function Cases() {
    const [filtro, setFilro] = useState("");
    const [value, setValues] = useState<FiltroOptions[]>([]);
    const [card, setCard] = useState<arrCard[]>([]);
    const [modalExcluirCase, setModalExcluirCase] = useState<boolean>(false);
    const {
        descriptionCase,
        modal,
        toggleModal,
        cases,
        caseId,
        setCaseId,
        cardCases,
        hasLimitCase,
        limitCase,
        deleteCase,

        motivoReprova,
        modalReprova,
    } = useCase();

    const excluir = {
        img: imgExcluir,
        title: (
            <Text as="p" fontWeight="600" fontSize="mdl" color="white">
                Tem certeza que deseja <br />
                excluir esse case?
            </Text>
        ),
        width: "200px",
        onClick: deleteCase,
        closeModal: closeModal,
    };

    const reprova = {
        img: imgCloseModal,
        titulo: "Observações",
        descricao: `<p>${motivoReprova}</p>`,
    };

    function closeModal() {
        setModalExcluirCase(false);
        setCaseId(undefined);
    }

    function onClick() {
        toggleModal(false);
    }

    function clickOutModal(e: React.MouseEvent<HTMLElement>) {
        const element = (e.target as HTMLElement).dataset;
        if (element?.closemodal) {
            toggleModal(!modal);
        }
    }

    useEffect(() => {
        if (Object.keys(cardCases).length) {
            const primaieraChave = Object.keys(cardCases);
            const segundaChave = primaieraChave.map((item: string) =>
                Object.keys(cardCases[item])
            );

            const arrTemp: arrCard[] = [];

            primaieraChave.forEach((value) => {
                primaieraChave.forEach((item, key) => {
                    segundaChave[key].forEach((seg) => {
                        const niveis = Object.keys(cardCases[item][`${seg}`]);

                        niveis.forEach((nivel) => {
                            const pilar = Object.values(
                                cardCases[item][`${seg}`][`${nivel}`]
                            );
                            const [fazer_melhor, humanizar, resolver] = pilar;

                            const obj = {
                                fazer_melhor: parseInt(fazer_melhor),
                                humanizar: parseInt(humanizar),
                                resolver: parseInt(resolver),
                                title: value,
                                nivel: nivel,
                                negocio: seg,
                            };

                            arrTemp.push(obj);
                        });
                    });
                });
            });

            setCard(arrTemp);
        }
    }, [cardCases]);

    useEffect(() => {
        if (card.length) {
            const limitCase = card.every(
                (item) =>
                    item.fazer_melhor === 3 &&
                    item.humanizar === 3 &&
                    item.resolver === 3
            );

            hasLimitCase(limitCase);
        }
    }, [card, hasLimitCase]);

    useEffect(() => {
        if (caseId) {
            setModalExcluirCase(true);
        } else {
            setModalExcluirCase(false);
        }
    }, [caseId]);

    return (
        <>
            <Menu />

            <div className="content">
                <Header
                    setFiltro={setFilro}
                    cases={cases?.data ? cases?.data : []}
                    limitCase={limitCase}
                />
                <SubHeader card={card} />
                <HeaderTable setValues={setValues} value={value} />
                <TableCase
                    filtro={filtro}
                    select={value}
                    setcaseId={setCaseId}
                />
            </div>

            <Modal
                component={BoxTexto}
                active={modal}
                onClick={clickOutModal}
                rest={{ ...descriptionCase, onClick }}
            />

            <Modal
                component={BoxExclusao}
                rest={excluir}
                active={modalExcluirCase}
            />

            <Modal
                component={BoxTexto}
                active={modalReprova}
                onClick={clickOutModal}
                rest={{ ...reprova, onClick }}
            />
        </>
    );
}
