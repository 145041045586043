import { useHistory } from "react-router-dom";
import { getLocalStore } from "../../util/helper";

import { NovoCase } from "../../components/NovoCase";
import { UseCaseProvider } from "../../hook/UseCase";

import { UserProvider } from "../../hook/UserContext";
import { NovoUsuario } from "../../components/NovoUsuario";

export function Novo() {
    const nivel = getLocalStore();
    const history = useHistory();

    switch (nivel?.nivel) {
        case "admin":
            return (
                <UserProvider>
                    <NovoUsuario />
                </UserProvider>
            );

        case "escreve o case":
            return (
                <UseCaseProvider>
                    <NovoCase />
                </UseCaseProvider>
            );
        default:
            history.goBack();
            return <></>;
    }
}
