import { Column } from "react-table";
import { Data } from "./types";

export const column: Column<Data>[] = [
    {
        Header: "Eps",
        accessor: "eps",
        width: 50,
        minWidth: 50,
    },
    {
        Header: "Negócio",
        accessor: "negocio",
        width: 50,
        minWidth: 50,
    },
    {
        Header: "Nível",
        accessor: "nivel",
        width: 60,
        minWidth: 60,
    },
    {
        Header: "Pilar",
        accessor: "pilar",
        width: 100,
        minWidth: 100,
    },
    {
        Header: "Título",
        accessor: "titulo",
        width: 50,
        minWidth: 50,
    },
    {
        Header: "Avaliação",
        accessor: "avaliacao",
        width: 150,
        minWidth: 150,
    },
    {
        Header: "Ler Case",
        accessor: "lerCase",
        width: 50,
        minWidth: 50,
    },
];
