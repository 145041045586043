import { Flex, Text } from "aplication-yetz";
import userImg from "../../assets/sistema/user-sistem.svg";

interface NomeUserProps {
    name: string;
}

export function NomeUser(props: NomeUserProps) {
    const { name } = props;
    return (
        <Flex alignItems="center">
            <img src={userImg} alt="" />
            <Text ml="16px" fontFamily="Roboto" color="white">
                {name}
            </Text>
        </Flex>
    );
}
