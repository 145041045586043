import styled from "styled-components";

export const PaginacaoStyles = styled.div`
    display: flex;
    align-items: center;
    button {
        width: 30px;
        height: 30px;
        margin-right: 20px;
    }
`;
