import styled from "styled-components";

export const SegmentacaoStyles = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 26px;
    border-bottom: 1px solid #ffffff3b;
    margin-top: 64px;
    margin-bottom: 32px;
    button {
        border: none;
        background-color: transparent;
        cursor: pointer;
    }
    h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: ${({ theme }) => theme.colors.white};
    }
`;

export const Modal = styled.div`
    background-color: ${({ theme }) => theme.colors.black};
    width: 680px;
    height: auto;
    padding: 64px 80px;

    button {
        &.close {
            border: none;
            background-color: transparent;
        }
    }
`;

export const CardStyles = styled.div`
    padding: 32px 34px 16px 27px;
    border: 1px solid ${({ theme }) => theme.colors.line};
    position: relative;
    border-radius: 8px;
    margin-bottom: 16px;
    &:last-child {
        margin-bottom: 0px;
    }
    button {
        border: none;
        background-color: transparent;
    }
    &:before {
        content: "";
        display: block;
        width: 36px;
        height: 1px;
        position: absolute;
        top: -2px;
        left: 27px;
        background: ${({ theme }) => theme.colors.sinalizacao};
    }
`;
