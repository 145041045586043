import { Box } from "aplication-yetz";
import { Formik, Form, Field, ErrorMessage, FormikProps } from "formik";
import { useUser } from "../../hook/UserContext";

import { initialValues, validadtion, MyFormValues } from "./type";

import { Button } from "../../components/Button";
import { Select } from "../../components/Select";

interface FomUsuarioProps {
    openModal: () => void;
}

export function FormUsuario(props: FomUsuarioProps) {
    const { perfil, addUser, loading } = useUser();
    return (
        <Box width={520} mb="64px">
            <Formik
                initialValues={initialValues}
                validationSchema={validadtion}
                onSubmit={(values, actions) => {
                    addUser(values);
                    actions.resetForm();
                }}
            >
                {(props: FormikProps<MyFormValues>) => {
                    const { setFieldValue } = props;

                    return (
                        <Form>
                            <div className="container-input">
                                <Field
                                    type="text"
                                    name="name"
                                    placeholder="Nome"
                                />
                                <ErrorMessage component="small" name="name" />
                            </div>
                            <div className="container-input">
                                <Field
                                    type="text"
                                    name="email"
                                    placeholder="Login"
                                    autoComplete="new-password"
                                />
                                <ErrorMessage component="small" name="email" />
                            </div>
                            <div className="container-input">
                                <Field
                                    type="password"
                                    name="password"
                                    placeholder="Senha"
                                    autoComplete="new-password"
                                />
                                <ErrorMessage
                                    component="small"
                                    name="password"
                                />
                            </div>
                            <Box height="56px">
                                <Select
                                    options={perfil}
                                    placeholder="Perfil"
                                    onChange={(value) => {
                                        setFieldValue("role", value.label);
                                    }}
                                />
                                <ErrorMessage component="small" name="role" />
                            </Box>

                            <Box width={202} mt={32}>
                                <Button
                                    title="Salvar"
                                    upperCase={true}
                                    loading={loading}
                                />
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    );
}
