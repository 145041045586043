import { useUser } from "../../hook/UserContext";
import { Text, Flex, Box } from "aplication-yetz";
import { SegmentacaoType } from "./type";

import editarImg from "../../assets/sistema/editar-cor-action.svg";
import excluirImg from "../../assets/sistema/excluir-cor-action.svg";
import estrelaImg from "../../assets/sistema/estrela.svg";
import favoritoImg from "../../assets/sistema/favorito.svg";
import diamanteImg from "../../assets/sistema/diamante.svg";

import { CardStyles } from "./styles";

interface SegmentacaoProps extends SegmentacaoType {
    label: string;
    value: number;
}

export function Card(props: SegmentacaoProps) {
    const { id, label } = props;
    const { deleSegmentacao, getSegmentacao } = useUser();

    function handleDelet() {
        if (id) {
            deleSegmentacao(id);
        }
    }

    return (
        <CardStyles>
            <Flex justifyContent="space-between" alignItems="flex-end">
                <Box>
                    <Text
                        fontSize="mdl"
                        fontWeight="600"
                        color="white"
                        mb="12px"
                    >
                        {label}
                    </Text>
                </Box>

                <Box>
                    <Flex width="56px" ml="auto" justifyContent="space-between">
                        <button
                            type="button"
                            onClick={() => getSegmentacao(props)}
                        >
                            <img src={editarImg} alt="" />
                        </button>

                        <button onClick={() => handleDelet()}>
                            <img src={excluirImg} alt="" />
                        </button>
                    </Flex>

                    <Flex
                        width="134px"
                        mt="27px"
                        pb="10px"
                        justifyContent="space-between"
                    >
                        <button>
                            <img src={estrelaImg} alt="" />
                        </button>
                        <button>
                            <img src={favoritoImg} alt="" />
                        </button>
                        <button>
                            <img src={diamanteImg} alt="" />
                        </button>
                    </Flex>
                </Box>
            </Flex>
        </CardStyles>
    );
}
