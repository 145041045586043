import React, { createContext, useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { api } from "../services/api";
import { v4 as uuidv4 } from "uuid";
import { SegmentacaoType } from "../components/NovoUsuario/type";
import { Option } from "../components/Select/types";
import { MyFormValues } from "../components/NovoUsuario/type";

interface UserProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

interface UserData {
    id: number;
    name: string;
    email: string;
    cases: [];
    roles: [
        {
            id: number;
            name: string;
        }
    ];
}

interface Perfil {
    [key: number]: string;
}

interface relation {
    id: number;
    eps: {
        name: string;
    };
    negocio: {
        name: string;
    };
    nivel: {
        name: string;
    };
}

interface UserDataArray {
    data: UserData[];
    last_page: number;
    current_page: number;
}

interface AddUserProps {
    id: number;
    name: string;
}

interface UserContextData {
    modal: boolean;
    modalSucesso: boolean;
    modalFail: boolean;

    loading: boolean;
    loadingMetaUser: boolean;

    segmentacaoArr: SegmentacaoType[];
    segmentacao: SegmentacaoType | undefined;
    users?: UserDataArray;
    paginacao: (id: number) => void;
    addUserId?: number;
    perfil: Option[];
    relation: Option[];

    openModal: () => void;
    closeModal: () => void;

    getSegmentacao: (values: SegmentacaoType | void) => void;

    addSegmentacao: (values: SegmentacaoType) => void;
    deleSegmentacao: (id: number | string) => void;
    updateSegmentacao: (values: SegmentacaoType) => void;

    addUser: (values: MyFormValues) => void;
    addMetaUser: (values: Array<number>) => void;
}

const UserContext = createContext<UserContextData>({} as UserContextData);

export function UserProvider(props: UserProviderProps) {
    const history = useHistory();
    const [users, setUsers] = useState<UserDataArray>();
    const [modal, setModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingMetaUser, setLoadingMetaUser] = useState<boolean>(false);
    const [modalSucesso, setModalSucesso] = useState<boolean>(false);
    const [modalFail, setModalFail] = useState<boolean>(false);
    const [perfil, setPerfil] = useState<Option[]>([{ label: "", value: 0 }]);
    const [relation, setRelation] = useState<Option[]>([]);
    const [addUserId, setAddUserId] = useState<number>();
    const [segmentacaoArr, setSegmentacaoArr] = useState<SegmentacaoType[]>([]);
    const [segmentacao, setSegmentacao] = useState<
        SegmentacaoType | undefined
    >();
    const id = uuidv4();

    useEffect(() => {
        async function getUser() {
            setLoading(true);
            try {
                const { data } = await api.get<UserDataArray>("/users");
                setUsers(data);
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        }

        getUser();
    }, []);

    useEffect(() => {
        async function getPerfil() {
            const { data } = await api.get<Perfil[]>("/users/roles");

            const arrKeys = Object.keys(data);

            const arrValues: Option[] = arrKeys.map((item) => {
                return {
                    value: parseInt(item),
                    label: data[parseInt(item)] as string,
                };
            });
            arrValues.unshift({ value: 0, label: "" });
            setPerfil(arrValues);
        }

        getPerfil();
    }, []);

    useEffect(() => {
        async function getRelations() {
            try {
                const { data } = await api.get<relation[]>("/meta_relation");

                const arrValues: Option[] = data.map((item) => {
                    const {
                        eps: { name: epsName },
                        negocio: { name: negocioName },
                        nivel: { name: nivelName },
                        id,
                    } = item;

                    return {
                        label: `${epsName} ->  ${negocioName} -> ${nivelName}`,
                        value: id,
                    };
                });

                setRelation(arrValues);
            } catch (error) {
                console.log(error);
            }
        }

        getRelations();
    }, []);

    function openModal() {
        setModal(true);
    }

    function closeModal() {
        setModal(false);
        setModalSucesso(false);
        setModalFail(false);
    }

    async function addUser(values: MyFormValues) {
        setLoading(true);
        try {
            const { data } = await api.post<AddUserProps>("/users", {
                ...values,
            });

            if (data.id) {
                setAddUserId(data.id);
            }

            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    async function addMetaUser(values: Array<number>) {
        setLoadingMetaUser(true);
        try {
            await api.post("/meta_user", {
                user_id: addUserId,
                meta_relations_id: values,
            });

            setLoadingMetaUser(false);
            setSegmentacaoArr([]);
            history.goBack();
        } catch (error) {
            setLoadingMetaUser(false);
        }
    }

    async function paginacao(id: number) {
        setLoading(true);
        try {
            const { data } = await api.get<UserDataArray>(`users?page=${id}`);
            setUsers(data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    function addSegmentacao(values: SegmentacaoType) {
        if (
            Object.keys(values.segmentacao).length &&
            values.segmentacao[0].value
        ) {
            setSegmentacaoArr([
                ...segmentacaoArr,
                { segmentacao: values.segmentacao, id },
            ]);
        }
    }

    function deleSegmentacao(id: number | string) {
        setSegmentacaoArr(segmentacaoArr.filter((seg) => seg.id !== id));
    }

    function updateSegmentacao(values: SegmentacaoType) {
        if (
            Object.keys(values.segmentacao).length &&
            values.segmentacao[0].value
        ) {
            const arrUpdateSeg = segmentacaoArr.map((seg) => {
                if (seg.id === values.id) {
                    return values;
                } else {
                    return seg;
                }
            });

            setSegmentacaoArr(arrUpdateSeg);
        }
    }

    function getSegmentacao(values: SegmentacaoType | any) {
        console.log(values);
        setSegmentacao(values && values);
        setModal(true);
    }

    const { children } = props;

    return (
        <UserContext.Provider
            value={{
                modal,
                modalSucesso,
                modalFail,

                openModal,
                closeModal,

                loading,
                loadingMetaUser,

                perfil,

                users,
                addUser,
                addMetaUser,
                paginacao,
                addUserId,

                relation,

                segmentacaoArr,
                getSegmentacao,
                segmentacao,

                addSegmentacao,
                updateSegmentacao,
                deleSegmentacao,
            }}
        >
            {children}
        </UserContext.Provider>
    );
}

export function useUser() {
    const context = useContext(UserContext);
    return context;
}
