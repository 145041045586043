import { Heading } from "aplication-yetz";
import { useHistory } from "react-router-dom";
import { LayoutEntityAddProps } from "./types";

import logoImg from "../../assets/sistema/logo-add.svg";
import setaImg from "../../assets/sistema/seta-add.svg";

import { Container } from "./styles";

export function LayoutEntityAdd(props: LayoutEntityAddProps) {
    const { title, children, width } = props;
    const history = useHistory();
    function back() {
        history.goBack();
    }

    return (
        <Container width={width}>
            <div className="esq">
                <img src={logoImg} alt="" />
            </div>
            <div className="dir">
                <div className="header" onClick={() => back()}>
                    <img src={setaImg} alt="" />
                    <Heading color="white" as="h2" ml="18px">
                        {title}
                    </Heading>
                </div>

                {children}
            </div>
        </Container>
    );
}
