import { Flex, Filter, Box } from "aplication-yetz";
import { ChangeEvent } from "react";
import { useCase } from "../../hook/UseCase";
import { NomeUser } from "../../components/NomeUser";
import { getLocalStore } from "../../util/helper";

import { BoxFilter, ContainerHeader } from "./styles";
import checkImg from "../../assets/sistema/check.svg";

interface HeaderProps {
    setFiltro: (e: string) => void;
}

export function Header(props: HeaderProps) {
    const { cases } = useCase();
    const { setFiltro } = props;

    var count = 0;

    if (cases?.data) {
        count = cases?.data.filter((item) => item.nota?.id).length;
    }

    const itens = getLocalStore();

    return (
        <ContainerHeader>
            <Flex justifyContent="space-between">
                <Box width="240px">
                    <NomeUser name={itens ? itens.user : ""} />
                </Box>

                <BoxFilter>
                    <Filter
                        iconFilter={false}
                        color="action"
                        borderColor="action"
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setFiltro(e.target.value)
                        }
                    />
                </BoxFilter>
                <Flex alignItems="center">
                    <div className="case-avaliados">
                        <img src={checkImg} alt="" />

                        <p>Cases avaliadoS</p>

                        <span>
                            {count ? count : null}
                            <small>/{cases?.total}</small>
                        </span>
                    </div>
                </Flex>
            </Flex>
        </ContainerHeader>
    );
}
