import styled from "styled-components";

export const Container = styled.div`
    width: 228px;
    height: 100vh;
    background: ${({ theme }) => theme.colors.primary};
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 3.125rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding-bottom: 2.313rem;
    button {
        margin-top: auto;
        background-color: transparent;
        border: none;
        color: ${({ theme }) => theme.colors.white};
        cursor: pointer;
    }
`;
