import { PageLogin } from "../pages/Login";
import { Novo } from "../pages/Novo";

import { Nivel } from "../pages/Nivel";

import { routesProps } from "./types";

export const routes: routesProps[] = [
    {
        path: "/sistema/editar/:id",
        component: Novo,
        isPrivate: true,
        exact: true,
    },
    {
        path: "/sistema/novo",
        component: Novo,
        isPrivate: true,
        exact: true,
    },
    {
        path: "/sistema/case",
        component: Nivel,
        isPrivate: true,
        exact: true,
    },

    { path: "/", exact: true, component: PageLogin, isPrivate: false },

    {
        path: "*",
        component: Nivel,
        isPrivate: true,
    },
];
