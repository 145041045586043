import { useEffect, useState } from "react";
import { Box, Table, Text } from "aplication-yetz";
import { Avaliacao } from "../Avalaicao";
import { column } from "./config";
import { Data } from "./types";
import { useCase } from "../../hook/UseCase";
import { filtroCase } from "../../util/helper";
import { PaginacaoUser } from "../Paginacao";

import { Option } from "../Select/types";
import { Loader } from "../../components/Loader";

import checkTrue from "../../assets/sistema/avaliacao/check-true.svg";
import checkFalse from "../../assets/sistema/avaliacao/check-false.svg";
import visualizarImg from "../../assets/sistema/visualizar.svg";

import { TableStyles } from "./styles";

interface FiltroOptions extends Option {
    tipo: string;
}

interface TableProps {
    filtro: string;
    select?: FiltroOptions[];
}

export function TableAvaliacao(props: TableProps) {
    const [currentPage, setSurrentPage] = useState<number>();
    const [lastPage, setLastPage] = useState<number>();
    const { cases, loading, handleDescriptionCase, paginacaoAvaliacao } =
        useCase();
    const { filtro, select } = props;

    const arrFilter = filtroCase(
        cases?.data ? cases?.data : [],
        filtro,
        select
    );

    const dataTemp: Data[] = arrFilter.map((item) => {
        const {
            meta_relacao,
            titulo,
            descricao,
            data_ligacao,
            hora_ligacao,
            id,
            nota,
            atitude_diferenciada,
            monitor,
        } = item;
        const { negocio, nivel, pilar } = meta_relacao;

        var img;

        var str = descricao ? descricao.replace(/\\n/g, "<br />") : "";
        str = str.replace(/[\\"]/g, "");

        const descriptionCase = {
            titulo,
            hora_ligacao: hora_ligacao ? hora_ligacao : "",
            data_ligacao: data_ligacao ? data_ligacao : "",
            descricao: str ? str : "",
            atitude: atitude_diferenciada ? atitude_diferenciada : "",
            monitor: monitor ? monitor : "",
        };

        switch (pilar.id) {
            case 3:
                img = "fazermelhor";
                break;

            case 2:
                img = "humanizarImg";
                break;

            case 1:
                img = "resolver";
                break;

            default:
                break;
        }

        return {
            negocio: (
                <Text ml="16px" color="white">
                    {negocio.name}
                </Text>
            ),
            nivel: (
                <Text ml="16px" color="white">
                    {nivel.name}
                </Text>
            ),
            pilar: (
                <div className={`pilar ${img}`}>
                    <Text pl="30px" color="white">
                        {pilar.name}
                    </Text>
                </div>
            ),
            titulo: (
                <Text ml="16px" color="white">
                    {titulo}
                </Text>
            ),
            check:
                nota?.nota || Number.isInteger(nota?.nota) ? (
                    <Box width="24px">
                        <img src={checkTrue} alt="" />
                    </Box>
                ) : (
                    <Box width="24px">
                        <img src={checkFalse} alt="" />
                    </Box>
                ),
            avaliacao: (
                <Avaliacao
                    rtl={true}
                    id={id}
                    nota={nota?.nota ? nota.nota : 0}
                />
            ),
            lerCase: (
                <Box width="24px" m="0 auto">
                    {str ? (
                        <div
                            className="button-table"
                            onClick={() =>
                                handleDescriptionCase(descriptionCase)
                            }
                        >
                            <img src={visualizarImg} alt="" />
                        </div>
                    ) : (
                        <></>
                    )}
                </Box>
            ),
        };
    });

    function paginacaoCases(id: number) {
        paginacaoAvaliacao(id);
    }

    useEffect(() => {
        if (cases?.current_page && cases.last_page) {
            setSurrentPage(cases.current_page);
            setLastPage(cases.last_page);
        }
    }, [cases?.current_page, cases?.last_page]);

    return (
        <TableStyles>
            {loading ? (
                <div className="loader">
                    <Loader width={300} height={300} />
                </div>
            ) : (
                <>
                    <Table columns={column} data={dataTemp} />
                    <PaginacaoUser
                        currentPage={currentPage}
                        lastPage={lastPage}
                        paginacao={paginacaoCases}
                    />
                </>
            )}
        </TableStyles>
    );
}
