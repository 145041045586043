import { useRef } from "react";
import { Box } from "aplication-yetz";
import { PreviewCadastro } from "../PreviewCadastro";
import { SubHeaderStyles } from "./styles";
import { Card } from "./card";
import { arrCard } from ".";

// import Swiper core and required modules
import SwiperCore, { Navigation, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";

// install Swiper modules
SwiperCore.use([Navigation, A11y]);

interface SubHeaderProps {
    card: arrCard[];
}

export function SubHeader(props: SubHeaderProps) {
    const { card } = props;
    const prevRef = useRef(null);
    const nextRef = useRef(null);

    return (
        <SubHeaderStyles>
            <PreviewCadastro />
            <div className="container-swiper">
                <div className="controles">
                    <span ref={prevRef} className="seta-esq"></span>
                    <span ref={nextRef} className="seta-dir"></span>
                </div>
                <Swiper
                    onInit={(swiper: any) => {
                        swiper.params.navigation.prevEl = prevRef.current;
                        swiper.params.navigation.nextEl = nextRef.current;
                    }}
                    grabCursor={true}
                    spaceBetween={16}
                    className="swiper-case"
                    breakpoints={{
                        "@0.00": {
                            slidesPerView: 2,
                        },
                    }}
                >
                    {card.length
                        ? card.map((item, key) => {
                              return (
                                  <SwiperSlide>
                                      <Box width="100%">
                                          <Card {...item} key={key} />
                                      </Box>
                                  </SwiperSlide>
                              );
                          })
                        : null}
                </Swiper>
            </div>
        </SubHeaderStyles>
    );
}
