import { useState } from "react";
import { Box, Heading } from "aplication-yetz";
import { Formik, Form, Field, ErrorMessage, FormikProps } from "formik";
import { useLogin } from "../../hook/UseLogin";
import { MyFormValues, initialValues, validation } from "./types";

import { Button } from "../Button";
import olhoImg from "../../assets/sistema/olho-login.svg";
import { Formstyles } from "./styles";
export function FormLogin() {
    const [type, setType] = useState("password");
    const { login, loading } = useLogin();

    function toogle() {
        if (type === "password") {
            setType("text");
        } else {
            setType("password");
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={(values, actions) => {
                login(values);
                actions.resetForm();
            }}
        >
            {(props: FormikProps<MyFormValues>) => {
                return (
                    <Form>
                        <Formstyles>
                            <Box width="381px" ml="auto" mr="139px">
                                <Heading as="h2" color="white" mb="24px">
                                    Acesse sua área
                                </Heading>
                                <div className="container-input email">
                                    <Field
                                        type="text"
                                        placeholder="E-mail Corporativo"
                                        name="email"
                                        autoComplete="new-password"
                                    />
                                </div>
                                <ErrorMessage component="small" name="email" />
                                <div className="container-input senha">
                                    <Field
                                        type={type}
                                        placeholder="Senha"
                                        name="password"
                                        autoComplete="new-password"
                                    />
                                    <button
                                        className={`change-password ${
                                            type === "text" ? "active" : ""
                                        }`}
                                        onClick={() => toogle()}
                                        type="button"
                                    >
                                        <img src={olhoImg} alt="" />
                                    </button>
                                </div>
                                <ErrorMessage
                                    component="small"
                                    name="password"
                                />

                                <Button title="Entrar" loading={loading} />
                            </Box>
                        </Formstyles>
                    </Form>
                );
            }}
        </Formik>
    );
}
