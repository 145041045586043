import { useEffect, useRef, useState } from "react";
import { useUser } from "../../hook/UserContext";
import { Text, Flex, Box } from "aplication-yetz";
import { Formik, Form, FormikProps, ErrorMessage } from "formik";
import {
    SegmentacaoInitialValues,
    validadtionSegmentacao,
    SegmentacaoType,
} from "./type";

import { Button, Cancelar } from "../../components/Button";
import { MultipleSelect } from "../../components/Select";
import { Option } from "../../components/Select/types";
import closeImg from "../../assets/sistema/close-modal.svg";

import { Modal } from "./styles";

export function Segmentacao() {
    const [initalValues, setInitalValues] = useState<SegmentacaoType>(
        SegmentacaoInitialValues
    );

    const {
        closeModal,
        addSegmentacao,
        segmentacao,
        updateSegmentacao,
        getSegmentacao,
        relation,
    } = useUser();

    const clearNivel = useRef<HTMLButtonElement>(null);

    const nivelUpdate: SegmentacaoType | undefined =
        segmentacao?.segmentacao && segmentacao;

    function clearSelect() {
        clearNivel.current && clearNivel.current.click();
        getSegmentacao();
        closeModal();
    }

    useEffect(() => {
        if (segmentacao && Object.keys(segmentacao).length) {
            setInitalValues(segmentacao);
        } else {
            setInitalValues({ segmentacao: [] });
        }
    }, [segmentacao]);

    const teste: Option[] = Object.values(
        relation.reduce(
            (acc, cur) => Object.assign(acc, { [cur.label]: cur }),
            {}
        )
    );

    return (
        <Modal>
            <Formik
                initialValues={initalValues}
                enableReinitialize
                validationSchema={validadtionSegmentacao}
                onSubmit={(values, actions) => {
                    if (!values.segmentacao.length) {
                        actions.setFieldError("nivel", "Campo Obrigatório");
                    } else {
                        if (!nivelUpdate) {
                            addSegmentacao(values);
                        } else {
                            if (segmentacao) {
                                updateSegmentacao(values);
                            }
                        }
                        clearSelect();
                        actions.setFieldValue("nivel", "");
                        actions.resetForm();
                    }
                }}
            >
                {(props: FormikProps<SegmentacaoType>) => {
                    const { setFieldValue } = props;

                    const title = !nivelUpdate
                        ? "Adicionar Segmento"
                        : "Editar Segmento";
                    return (
                        <Form>
                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                                mb="39px"
                            >
                                <Text fontSize="mdl" color="white" bold={true}>
                                    {title}
                                </Text>

                                <button
                                    type="button"
                                    className="close"
                                    onClick={() => clearSelect()}
                                >
                                    <img src={closeImg} alt="" />
                                </button>
                            </Flex>

                            <Flex mb="39px" flexDirection="column">
                                <MultipleSelect
                                    value={
                                        nivelUpdate
                                            ? nivelUpdate.segmentacao
                                            : []
                                    }
                                    clear={clearNivel}
                                    placeholder="Segmentacao"
                                    options={teste}
                                    onChange={(op) => {
                                        setFieldValue(
                                            "segmentacao",
                                            op.map((item) => item)
                                        );
                                    }}
                                />
                                <ErrorMessage
                                    component="small"
                                    name="segmentacao"
                                />
                            </Flex>

                            <Flex>
                                <Box width="217px">
                                    <Button title={title} upperCase={true} />
                                </Box>
                                <Box width="136px" ml="24px">
                                    <Cancelar
                                        title="cancelar"
                                        upperCase={true}
                                        onClick={() => clearSelect()}
                                    />
                                </Box>
                            </Flex>
                        </Form>
                    );
                }}
            </Formik>
        </Modal>
    );
}
