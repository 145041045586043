import { Box, Flex, Text } from "aplication-yetz";
import { useHistory } from "react-router-dom";
import { useCase, CasesData } from "../../hook/UseCase";
import { Formik, Form, Field, ErrorMessage, FormikProps } from "formik";
import { initialValuesCase, validadtionCase, CaseValues } from "./types";
import { DateTime } from "../Select";
import { Button, Cancelar } from "../Button";
import { DrafEditor } from "../Editor";
import { useEffect, useRef, useState } from "react";

interface CaseProps {
    caseById?: CasesData[];
    caseId?: number;
    setQtdCaractere: (excede: boolean) => void;
}

export function Case(props: CaseProps) {
    const { caseById, caseId, setQtdCaractere } = props;
    const history = useHistory();
    const { addCase } = useCase();
    const ref = useRef(null);
    const [initialvalues, setInitialvalues] =
        useState<CaseValues>(initialValuesCase);

    function cancelar() {
        if (caseId) {
            history.push({
                pathname: `/sistema/editar/${caseId}`,
                state: {
                    id: caseId,
                },
            });
            window.location.reload();
        } else {
            history.push("/sistema");
        }
    }

    useEffect(() => {
        if (caseById?.length) {
            const {
                data_ligacao,
                hora_ligacao,
                descricao,
                atitude_diferenciada = "",
            } = caseById[0];

            var str = descricao ? descricao.replace(/\\n/g, "<br />") : "";
            str = str.replace(/[\\"]/g, "");

            setInitialvalues({
                atitude_diferenciada,
                dataTime: `${data_ligacao} ${hora_ligacao}`,
                descricao: str,
            });
        }
    }, [caseById]);

    return (
        <Formik
            initialValues={initialvalues}
            enableReinitialize
            validationSchema={validadtionCase}
            onSubmit={(values, actions) => {
                if (
                    values.descricao.replace(new RegExp("<[^>]*>", "g"), "")
                        .length >= 2000
                ) {
                    setQtdCaractere(true);
                    if (ref.current) {
                        const btnUndo = (ref.current as Element).querySelector(
                            'div[title="Undo"]'
                        ) as HTMLElement;

                        btnUndo.click();
                    }
                } else {
                    addCase(values);
                }
            }}
        >
            {(props: FormikProps<CaseValues>) => {
                const { setFieldValue, values } = props;

                return (
                    <Form>
                        <div className="container-input">
                            <DateTime
                                initialValue={
                                    values.dataTime !== ""
                                        ? new Date(values.dataTime)
                                        : null
                                }
                                onChange={(data) => {
                                    setFieldValue("dataTime", data);
                                }}
                            />
                            <ErrorMessage component="small" name="dataTime" />
                        </div>

                        <div className="container-input">
                            <Field
                                type="text"
                                name="atitude_diferenciada"
                                placeholder="Atitude diferenciada"
                                value={values.atitude_diferenciada}
                            />
                            <ErrorMessage
                                component="small"
                                name="atitude_diferenciada"
                            />
                        </div>

                        <Text color="white" mb="8px" as="p">
                            Escreva o case abaixo
                        </Text>

                        <div className="container-input editor" ref={ref}>
                            <DrafEditor
                                values={initialvalues.descricao}
                                onChange={(data) => {
                                    if (
                                        data.replace(
                                            new RegExp("<[^>]*>", "g"),
                                            ""
                                        ).length >= 2000
                                    ) {
                                        if (ref.current) {
                                            const btnUndo = (
                                                ref.current as Element
                                            ).querySelector(
                                                'div[title="Undo"]'
                                            ) as HTMLElement;

                                            btnUndo.click();
                                        }
                                        setQtdCaractere(true);
                                    }

                                    setFieldValue(
                                        "descricao",
                                        JSON.stringify(data)
                                    );
                                }}
                            />
                            <ErrorMessage component="small" name="descricao" />
                        </div>
                        <Box width="268px" pt="32px">
                            <Flex justifyContent="space-between">
                                <Box width="116px">
                                    <Cancelar
                                        title="Voltar"
                                        upperCase={true}
                                        onClick={() => cancelar()}
                                    />
                                </Box>

                                <Box width="116px">
                                    <Button title="Avançar" upperCase={true} />
                                </Box>
                            </Flex>
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
}
