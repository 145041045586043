import { getLocalStore } from "../../util/helper";
import { Usuario } from "../Usuario";
import { AvaliarBanca } from "../AvaliarBanca";
import { Case } from "../Case";
import { AvaliarEngajamento } from "../AvaliarEngajamento";
import { ADMIN, ESCREVE_CASE, AVALIADOR, ENGAJAMENTO } from "../../config";

export function Nivel() {
    const nivel = getLocalStore();

    switch (nivel?.nivel) {
        case ADMIN:
            return <Usuario />;

        case ESCREVE_CASE:
            return <Case />;

        case AVALIADOR:
            return <AvaliarBanca />;

        case ENGAJAMENTO:
            return <AvaliarEngajamento />;

        default:
            return <></>;
    }
}
