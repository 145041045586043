import styled from "styled-components";

export const Container = styled.div`
    background: #141e26;
    border-radius: 8px;
    width: 384px;
    height: 149px;
    padding: 22px 0px 20px 39px;

    h1 {
        font-style: normal;
        font-weight: bold;
        font-size: 60.3141px;
        color: ${({ theme }) => theme.colors.sinalizacao};
        position: relative;
        font-family: "Inter" sans-serif;
        padding-left: 40px;
        img {
            position: absolute;
            top: 13px;
            left: 0px;
        }
        span {
            font-size: 40px;
            color: rgba(255, 255, 255, 0.3);
        }

        small {
            font-style: normal;
            font-weight: 500;
            font-size: 30.1571px;
            line-height: 36px;
            color: ${({ theme }) => theme.colors.white};
        }
    }
`;
