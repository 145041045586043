import { Container, Cancelar as CancelarStyles } from "./styles";
import { Loader } from "../Loader";
import { ButtonProps } from "./types";

export function Button(props: ButtonProps) {
    const {
        title,
        onClick,
        upperCase = false,
        loading = false,
        disabled = false,
    } = props;

    return (
        <Container
            upperCase={upperCase}
            onClick={onClick}
            disabled={disabled}
            type="submit"
            loadingButton={loading as boolean}
        >
            {loading ? <Loader width={30} height={30} /> : title}
        </Container>
    );
}

export function Cancelar(props: ButtonProps) {
    const { title, onClick, upperCase = false } = props;

    return (
        <CancelarStyles upperCase={upperCase} onClick={onClick} type="button">
            {title}
        </CancelarStyles>
    );
}
