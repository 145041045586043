import styled from "styled-components";

interface ContainerProps {
    width?: number;
}

export const Container = styled.div<ContainerProps>`
    background: ${({ theme }) => theme.colors.fundoBase};
    border-radius: 16px;
    min-height: 239px;

    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    padding: 0 80px;

    width: ${(props) => (props.width ? props.width + "px" : "350px")};
    position: relative;
    .close-notificacao {
        position: absolute;
        top: 25px;
        right: 25px;
        cursor: pointer;
    }

    p {
        width: fit-content;
    }

    img {
        margin-bottom: 16px;
    }
`;

export const Excluir = styled.div<ContainerProps>`
    background: ${({ theme }) => theme.colors.fundoBase};
    border-radius: 16px;
    height: auto;

    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    padding: 64px 80px;

    width: ${(props) => (props.width ? props.width : "350px")};

    .container-input {
        height: auto;
        textarea {
            height: auto;
        }
    }
    p {
        width: fit-content;
    }

    img {
        margin-bottom: 16px;
    }
`;

export const Detalhe = styled.div<ContainerProps>`
    background: ${({ theme }) => theme.colors.fundoBase};
    border-radius: 16px;
    min-height: 239px;
    padding: 67px 80px;
    width: ${(props) => (props.width ? props.width + "px" : "350px")};
    .content-text {
        color: ${({ theme }) => theme.colors.white};
        overflow-x: hidden;
        height: calc(70vh - 39px - 39px - 53px - 42px - 21px);
        padding-right: 30px;
        &::-webkit-scrollbar {
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar:vertical {
            width: 5px;
        }

        &::-webkit-scrollbar:horizontal {
            height: 12px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(89, 92, 118, 0.5);
        }

        &::-webkit-scrollbar-track {
            background-color: rgba(255, 255, 255, 0.1);
        }
    }
    .close {
        img {
            cursor: pointer;
        }
    }
    p {
        width: fit-content;
    }

    hr {
        border: 1px solid #13393b;
    }

    img {
        margin-bottom: 16px;
    }
`;
