export const colors = {
    primary:
        "linear-gradient(180deg, #242D40 -100%, #242D40 -76%, #13393B -34%, #13393B 0%, #13393B 36%, #242D40 78%, #242D40 100%);",
    secondary: "#203742",
    black: "#17222C",

    gray700: "#858585",

    action: "#19FFFF",
    sinalizacao: "#FABFFF",
    fundoBase: "#152730",
    line: "#13393B",
    sinalizacaoErro: "#FF375B",
};

export const fonts = {
    xl: "2.625rem",
    lg: "1.75rem",
    mdl: "1.25rem",
    md: "1rem",
    sm: "0.875rem",
    xss: "0.75rem",
};
