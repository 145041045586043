import { Usuarios } from "../../components/Usuarios";
import { UserProvider } from "../../hook/UserContext";

export function Usuario() {
    return (
        <UserProvider>
            <Usuarios />
        </UserProvider>
    );
}
