import * as Yup from "yup";
import { Option } from "../../components/Select/types";

export interface MyFormValues {
    name: string;
    email: string;
    password: string;
    role: string;
}

export interface SegmentacaoType {
    id?: number | string;
    segmentacao: Option[] | [];
}

export interface RelationsType {
    meta_relations_id: Array<number>;
}

export const initialValues: MyFormValues = {
    name: "",
    email: "",
    password: "",
    role: "",
};

export const SegmentacaoInitialValues: SegmentacaoType = {
    segmentacao: [],
};

export const RelationsInitialValues: RelationsType = {
    meta_relations_id: [],
};

export const validadtion = Yup.object().shape({
    name: Yup.string().required("Campo Obrigatório"),
    email: Yup.string()
        .email("Insira um E-mail válido")
        .required("E-mail e um campo obrigatório"),
    password: Yup.string().required("Campo Obrigatório"),
    role: Yup.string().required("Campo Obrigatório"),
});

export const validadtionSegmentacao = Yup.object().shape({
    segmentacao: Yup.array().of(
        Yup.object().shape({
            label: Yup.string().required("Campo Obrigatório"),
            value: Yup.number().required("Campo Obrigatório"),
        })
    ),
});

export const validadtionRelations = Yup.object().shape({
    meta_relations_id: Yup.array().of(
        Yup.number().required("Campo Obrigatório")
    ),
});
